import React, { useState, useEffect } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Button, Chip } from "@mui/material";
import SidebarNav from "./../sidebar";
import { DataGrid } from "@mui/x-data-grid";
import "font-awesome/css/font-awesome.min.css";

const StockExpire = () => {
  const [stocks, setStocks] = useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const stocksResponse = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/api/expiry-reminder-list"
      );
      const stocksData = await stocksResponse.json();
      setStocks(stocksData);
    } catch (error) {
      console.error("Error fetching stocks data: ", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/expiry-reminder-list?limit=${
          pageState.pageSize
        }&offset=${(pageState.page - 1) * 10}&pageNo=${pageState.page}`
      );
      const json = await response.json();
      console.log("json", json);
      setStocks(json);
      const updated_data_purchage =
        stocks?.results?.map((product, index) => {
          return {
            id: index,
            product: product.product_name,
            variant: product.value,
            expiry_date: new Date(product.expiry_date).toDateString(),
            price: "₹ " + Math.ceil(product.price).toLocaleString("en-IN"),
            quantity: product.quantity,
            status: product.quantity === 0 ? "Out of Stock" : "Low Stock",
            hsn_sac: product.HSN_SAC || "N/A",
            unit: product.unit,
          };
        }) || [];

      console.log("updated", updated_data_purchage);

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
    };
    fetchData();
    // loadPurchaseList();
  }, [pageState.total]);

  const downloadCSV = () => {
    if (!stocks || !stocks.results) return;

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "Product,Variant,Expiry Date,Price,Quantity,Status,HSN / SAC,Unit\n";

    stocks?.results?.forEach((product) => {
      product.variants.forEach((variant) => {
        const row = [
          product.name,
          variant.value,
          variant.expiry_date,
          `₹ ${Math.ceil(variant.price).toLocaleString("en-IN")}`,
          variant.quantity,
          variant.quantity > 0 ? "In Stock" : "Out of Stock",
          variant.HSN_SAC || "N/A",
          variant.unit,
        ].join(",");
        csvContent += row + "\n";
      });
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "stock_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    { field: "product", headerName: "Product", width: 300 },
    { field: "variant", headerName: "Variant", width: 150 },
    { field: "expiry_date", headerName: "Expiry Date", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          style={{
            backgroundColor:
              params.value === "In Stock" ? "#4caf50" : "lightcoral",
            color: "white",
          }}
        />
      ),
    },
    { field: "hsn_sac", headerName: "HSN / SAC", width: 150 },
    { field: "unit", headerName: "Unit", width: 150 },
  ];

  let rows = [];

  if (stocks) {
    stocks.results.forEach((product) => {
      product?.variants?.forEach((variant, index) => {
        rows.push({
          id: index,
          product: product.name,
          variant: variant.value,
          expiry_date: variant.expiry_date,
          price: "₹ " + Math.ceil(variant.price).toLocaleString("en-IN"),
          quantity: variant.quantity,
          status: variant.quantity > 0 ? "In Stock" : "Out of Stock",
          hsn_sac: variant.HSN_SAC || "N/A",
          unit: variant.unit,
        });
      });
    });
  }
  console.log("here coming");
  if (pageState.data.length > 0) {
    rows = pageState.data;
  }

  return (
    <>
      <Header />
      <div className="main-body-parent">
        <SidebarNav />
        <div className="container pb-3 pt-3 main-body">
          <div className="row">
            <div className="col-md-8">
              <h3 className="mb-4 my-2 heading-text">Stock Expiry List</h3>
            </div>
            <div className="col-md-4 text-right">
              <input
                className="mb-4 my-2"
                type="text"
                placeholder="Search Products...."
              />
            </div>
          </div>
          <div className="card">
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                rowCount={pageState.total}
                loading={pageState.isLoading}
                columns={columns}
                pagination
                page={pageState.page - 1}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPaginationModelChange={(newPageSize) =>
                  setPageState((old) => ({
                    ...old,
                    pageSize: newPageSize.pageSize,
                    page: newPageSize.page + 1,
                  }))
                }
                onPageChange={(newPage) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                onPageSizeChange={(newPageSize) =>
                  setPageState((old) => ({ ...old, pageSize: newPageSize }))
                }
                sx={{
                  height: 400,

                  "& .MuiDataGrid-columnHeaders": {
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    marginTop: "10px",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    marginTop: "10px",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StockExpire;
