import React, { Component } from "react";
// import Select from "react-select";
import Header from "./header";
import Footer from "./footer";
import SidebarNav from "./sidebar";
import { Collapse, Select } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { calculateTax } from "../helpers/purchase";
const { Panel } = Collapse;

export default class CreatePurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchase: {
        party_name: "",
        party_address: "",
        bill_amount: "",
        invoice_number: "",
        invoice_number_new: "",
        invoice_date: "",
        waybill_no: "",
        gstin: "",
        remarks: "",
        value: "",
        quantity: "",
        unit: "",
        expiry_date: "",
        rate: "",
        total_amount: "",
        taxable: "",
        Tax_Rate: "",
        SGST_Amount: "",
        CGST_Amount: "",
        IGST_Amount: "",
        HSN_SAC: "",
        Max_Retail_Price: "",
        Selling_Price: "",
        Selling_Tax_Rate: "",
        state: "",
        state_code: "",
        product: "",
        variant: "",
      },
      products: "",
      variants: "",
      purchases: "",

      showNewInvoiceSection: true,
      showNewVariantSection: true,

      stateList: [],
    };

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/product-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ products: data }));

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/variant-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ variants: data }));

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/retail-purchase-list-2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ purchases: data }));

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/state-list`)
      .then((response) => this.setState({ stateList: response.data }));
  }

  loadVariantsByProductId = (id) => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/api/variant-list-by-product-id?product_id=" +
          id
      )
      .then((response) => {
        this.setState({ variants: response.data?.data });
      });
  };

  updatePurchaseDetails = (e) => {
    let purchasedetails = this.state.purchase;
    purchasedetails[e.target.name] = e.target.value;
    if (
      e.target.name == "rate" ||
      e.target.name == "quantity" ||
      e.target.name == "total_amount" ||
      e.target.name == "Tax_Rate"
    ) {
      purchasedetails["total_amount"] =
        Math.round(
          parseFloat(purchasedetails["rate"]) *
            parseInt(purchasedetails["quantity"]) *
            100
        ) / 100;
      // }

      // if (e.target.name == "total_amount" || e.target.name == "Tax_Rate") {
      console.log(1);
      let tax = calculateTax(
        purchasedetails["total_amount"],
        purchasedetails["Tax_Rate"]
      );
      console.log("TAX", tax);
      purchasedetails["taxable"] = tax.taxable_amount;
      purchasedetails["CGST_Amount"] = tax.cgst;
      purchasedetails["SGST_Amount"] = tax.sgst;
      purchasedetails["IGST_Amount"] = tax.tax_amount;
    }
    this.setState({ purchase: purchasedetails });
  };

  updateInvoiceNumber = (e) => {
    let purchasedetails = this.state.purchase;
    if (e == null) {
      purchasedetails["invoice_number"] = "";
      this.setState({ showNewInvoiceSection: true });
    } else {
      purchasedetails["invoice_number"] = e;
      this.setState({ showNewInvoiceSection: false });
    }
    this.setState({ purchase: purchasedetails });
  };

  updateStateCode = (e) => {
    let purchasedetails = this.state.purchase;
    if (e == null) {
      purchasedetails["state_code"] = "";
    } else {
      purchasedetails["state_code"] = e;
    }
    this.setState({ purchase: purchasedetails });
  };

  updateProduct = (e) => {
    let purchasedetails = this.state.purchase;
    if (e == null) {
      purchasedetails["product"] = "";
    } else {
      purchasedetails["product"] = e;
      this.loadVariantsByProductId(e);
    }
    this.setState({ purchase: purchasedetails });
  };

  updateVariant = (e) => {
    let purchasedetails = this.state.purchase;
    if (e == null) {
      purchasedetails["variant"] = "";
      this.setState({ showNewVariantSection: true });
    } else {
      purchasedetails["variant"] = e;
      this.setState({ showNewVariantSection: false });
    }
    this.setState({ purchase: purchasedetails });
  };

  updateUnit = (e) => {
    let purchasedetails = this.state.purchase;
    if (e == null) {
      purchasedetails["unit"] = "";
    } else {
      purchasedetails["unit"] = e;
    }
    this.setState({ purchase: purchasedetails });
  };

  createPurchaseEntry = async () => {
    if (
      this.state.purchase["party_name"] != "" &&
      this.state.purchase["party_address"] != "" &&
      this.state.purchase["state_code"] != "" &&
      this.state.purchase["product"] != "" &&
      this.state.purchase["rate"] != "" &&
      this.state.purchase["quantity"] != "" &&
      this.state.purchase["total_amount"] != "" &&
      this.state.purchase["Tax_Rate"] != "" &&
      this.state.purchase["taxable"] != "" &&
      this.state.purchase["CGST_Amount"] != "" &&
      this.state.purchase["SGST_Amount"] != "" &&
      this.state.purchase["IGST_Amount"] != ""
    ) {
      if (this.state.purchase["invoice_number"] != "") {
        if (this.state.purchase["variant"] != "") {
          const postResponse = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/api/retail-purchase-list",
            {
              method: "POST",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify({
                party_name: this.state.purchase["party_name"],
                party_address: this.state.purchase["party_address"],
                state_code: this.state.purchase["state_code"],
                product: this.state.purchase["product"],
                rate: this.state.purchase["rate"],
                quantity: this.state.purchase["quantity"],
                total_amount: this.state.purchase["total_amount"],
                Tax_Rate: this.state.purchase["Tax_Rate"],
                taxable: this.state.purchase["taxable"],
                CGST_Amount: this.state.purchase["CGST_Amount"],
                SGST_Amount: this.state.purchase["SGST_Amount"],
                IGST_Amount: this.state.purchase["IGST_Amount"],

                invoice_number: this.state.purchase["invoice_number"],
                bill_amount: this.state.purchase["bill_amount"],
                invoice_date: this.state.purchase["invoice_date"],
                variant: this.state.purchase["variant"],

                waybill_no: this.state.purchase["waybill_no"],
                gstin: this.state.purchase["gstin"],
                remarks: this.state.purchase["remarks"],
              }),
            }
          );
          const data = await postResponse.json();
          Swal.fire(
            "Success",
            "Purchase Entry Created Successfully!",
            "success"
          );
        } else {
          if (
            this.state.purchase["value"] != "" &&
            this.state.purchase["expiry_date"] != "" &&
            this.state.purchase["unit"] != "" &&
            this.state.purchase["Max_Retail_Price"] != "" &&
            this.state.purchase["Selling_Price"] != "" &&
            this.state.purchase["Selling_Tax_Rate"] != "" &&
            this.state.purchase["HSN_SAC"] != ""
          ) {
            const postResponse = await fetch(
              process.env.REACT_APP_API_BASE_URL + "/api/retail-purchase-list",
              {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                  party_name: this.state.purchase["party_name"],
                  party_address: this.state.purchase["party_address"],
                  state_code: this.state.purchase["state_code"],
                  product: this.state.purchase["product"],
                  rate: this.state.purchase["rate"],
                  quantity: this.state.purchase["quantity"],
                  total_amount: this.state.purchase["total_amount"],
                  Tax_Rate: this.state.purchase["Tax_Rate"],
                  taxable: this.state.purchase["taxable"],
                  CGST_Amount: this.state.purchase["CGST_Amount"],
                  SGST_Amount: this.state.purchase["SGST_Amount"],
                  IGST_Amount: this.state.purchase["IGST_Amount"],

                  invoice_number: this.state.purchase["invoice_number"],
                  bill_amount: this.state.purchase["bill_amount"],
                  invoice_date: this.state.purchase["invoice_date"],

                  variant: this.state.purchase["variant"],
                  value: this.state.purchase["value"],
                  expiry_date: this.state.purchase["expiry_date"],
                  unit: this.state.purchase["unit"],
                  HSN_SAC: this.state.purchase["HSN_SAC"],
                  Selling_Tax_Rate: this.state.purchase["Selling_Tax_Rate"],
                  Selling_Price: this.state.purchase["Selling_Price"],
                  Max_Retail_Price: this.state.purchase["Max_Retail_Price"],

                  waybill_no: this.state.purchase["waybill_no"],
                  gstin: this.state.purchase["gstin"],
                  remarks: this.state.purchase["remarks"],
                }),
              }
            );
            const data = await postResponse.json();
            Swal.fire(
              "Success",
              "Purchase Entry Created Successfully!",
              "success"
            );
          } else {
            Swal.fire("Failed!", "Please enter all fields.", "error");
          }
        }
      } else {
        if (
          this.state.purchase["invoice_number_new"] != "" &&
          this.state.purchase["bill_amount"] != "" &&
          this.state.purchase["invoice_date"] != ""
        ) {
          if (this.state.purchase["variant"] != "") {
            const postResponse = await fetch(
              process.env.REACT_APP_API_BASE_URL + "/api/retail-purchase-list",
              {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                  party_name: this.state.purchase["party_name"],
                  party_address: this.state.purchase["party_address"],
                  state_code: this.state.purchase["state_code"],
                  product: this.state.purchase["product"],
                  rate: this.state.purchase["rate"],
                  quantity: this.state.purchase["quantity"],
                  total_amount: this.state.purchase["total_amount"],
                  Tax_Rate: this.state.purchase["Tax_Rate"],
                  taxable: this.state.purchase["taxable"],
                  CGST_Amount: this.state.purchase["CGST_Amount"],
                  SGST_Amount: this.state.purchase["SGST_Amount"],
                  IGST_Amount: this.state.purchase["IGST_Amount"],

                  invoice_number: this.state.purchase["invoice_number_new"],
                  bill_amount: this.state.purchase["bill_amount"],
                  invoice_date: this.state.purchase["invoice_date"],
                  variant: this.state.purchase["variant"],

                  waybill_no: this.state.purchase["waybill_no"],
                  gstin: this.state.purchase["gstin"],
                  remarks: this.state.purchase["remarks"],
                }),
              }
            );
            const data = await postResponse.json();
            Swal.fire(
              "Success",
              "Purchase Entry Created Successfully!",
              "success"
            );
          } else {
            if (
              this.state.purchase["value"] != "" &&
              this.state.purchase["expiry_date"] != "" &&
              this.state.purchase["unit"] != "" &&
              this.state.purchase["Max_Retail_Price"] != "" &&
              this.state.purchase["Selling_Price"] != "" &&
              this.state.purchase["Selling_Tax_Rate"] != "" &&
              this.state.purchase["HSN_SAC"] != ""
            ) {
              const postResponse = await fetch(
                process.env.REACT_APP_API_BASE_URL +
                  "/api/retail-purchase-list",
                {
                  method: "POST",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify({
                    party_name: this.state.purchase["party_name"],
                    party_address: this.state.purchase["party_address"],
                    state_code: this.state.purchase["state_code"],
                    product: this.state.purchase["product"],
                    rate: this.state.purchase["rate"],
                    quantity: this.state.purchase["quantity"],
                    total_amount: this.state.purchase["total_amount"],
                    Tax_Rate: this.state.purchase["Tax_Rate"],
                    taxable: this.state.purchase["taxable"],
                    CGST_Amount: this.state.purchase["CGST_Amount"],
                    SGST_Amount: this.state.purchase["SGST_Amount"],
                    IGST_Amount: this.state.purchase["IGST_Amount"],

                    invoice_number: this.state.purchase["invoice_number_new"],
                    bill_amount: this.state.purchase["bill_amount"],
                    invoice_date: this.state.purchase["invoice_date"],

                    variant: this.state.purchase["variant"],
                    value: this.state.purchase["value"],
                    expiry_date: this.state.purchase["expiry_date"],
                    unit: this.state.purchase["unit"],
                    HSN_SAC: this.state.purchase["HSN_SAC"],
                    Selling_Tax_Rate: this.state.purchase["Selling_Tax_Rate"],
                    Selling_Price: this.state.purchase["Selling_Price"],
                    Max_Retail_Price: this.state.purchase["Max_Retail_Price"],

                    waybill_no: this.state.purchase["waybill_no"],
                    gstin: this.state.purchase["gstin"],
                    remarks: this.state.purchase["remarks"],
                  }),
                }
              );
              const data = await postResponse.json();
              Swal.fire(
                "Success",
                "Purchase Entry Created Successfully!",
                "success"
              );
            } else {
              Swal.fire("Failed!", "Please enter all fields.", "error");
            }
          }
        } else {
          Swal.fire("Failed!", "Please enter all fields.", "error");
        }
      }
    } else {
      Swal.fire("Failed!", "Please enter all fields.", "error");
    }
  };

  render() {
    let invoiceoptions = [];
    for (let k = 0; k < this.state.purchases.length; k++) {
      let invoiceoption = {
        value: "",
        label: "",
      };
      invoiceoption.value = invoiceoption.label =
        this.state.purchases[k].invoice_number;
      invoiceoptions.push(invoiceoption);
    }

    let productoptions = [];
    for (let i = 0; i < this.state.products.length; i++) {
      let productoption = {
        value: "",
        label: "",
      };

      productoption.value = this.state.products[i].id;
      productoption.label = this.state.products[i].name;
      productoptions.push(productoption);
    }

    let variantoptions = [];
    for (let i = 0; i < this.state.variants.length; i++) {
      let variantoption = {
        value: "",
        label: "",
      };
      for (let j = 0; j < this.state.products.length; j++) {
        if (this.state.products[j].id === this.state.variants[i].product) {
          variantoption.label =
            this.state.variants[i].value +
            " Expiry - " +
            this.state.variants[i].expiry_date;
        }
      }
      variantoption.value = this.state.variants[i].id;
      variantoptions.push(variantoption);
    }

    let unitoptions = [
      { value: "PC", label: "PC" },
      { value: "KG", label: "KG" },
    ];

    return (
      <>
        <Header />{" "}
        <div className="main-body-parent">
          <SidebarNav />
          <div className="container pb-3 pt-3 main-body">
            <h3 className="mb-4 my-2 heading-text">Create Purchase</h3>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Party Details" key="1" active>
                <div className="row" style={{ padding: "1em" }}>
                  <div className="col-md-12 form-group p_star">
                    <label>Party Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="party_name"
                      name="party_name"
                      onInput={this.updatePurchaseDetails}
                    />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <label>Party Address</label>
                    <textarea
                      className="form-control"
                      id="party_address"
                      name="party_address"
                      onInput={this.updatePurchaseDetails}
                    />
                  </div>

                  <div className="col-md-12 form-group p_star">
                    <label>Invoice Number</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      options={invoiceoptions}
                      onChange={this.updateInvoiceNumber}
                      isClearable={true}
                    />
                  </div>

                  {this.state.showNewInvoiceSection ? (
                    <React.Fragment>
                      <div
                        className="col-md-4 form-group p_star"
                        id="invoice_number"
                      >
                        <label>Invoice number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="invoice_number_new"
                          onInput={this.updatePurchaseDetails}
                        />
                      </div>
                      <div
                        className="col-md-4 form-group p_star"
                        id="bill_amount"
                      >
                        <label>Bill Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          name="bill_amount"
                          onInput={this.updatePurchaseDetails}
                          min={1}
                        />
                      </div>

                      <div
                        className="col-md-4 form-group p_star"
                        id="invoice_date"
                      >
                        <label>Invoice Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="invoice_date"
                          onInput={this.updatePurchaseDetails}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div />
                  )}

                  <div className="col-md-4 form-group p_star">
                    <label>Waybill Number (optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="waybill_number"
                      name="waybill_no"
                      onInput={this.updatePurchaseDetails}
                    />
                  </div>
                  <div className="col-md-4 form-group p_star">
                    <label>GSTIN (optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="gstin"
                      name="gstin"
                      onInput={this.updatePurchaseDetails}
                    />
                  </div>
                  <div className="col-md-4 form-group p_star">
                    <label>State Code</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      options={this.state.stateList?.map((item) => {
                        return {
                          value: item.code,
                          label: item.name + " -  Code(" + item.code + ")",
                        };
                      })}
                      name="state_code"
                      onChange={this.updateStateCode}
                      isClearable={true}
                      // value={19}
                    />
                  </div>

                  <div className="col-md-12 form-group p_star">
                    <label>Remarks (optional)</label>
                    <textarea
                      className="form-control"
                      id="remarks"
                      name="remarks"
                      onInput={this.updatePurchaseDetails}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </Panel>
              <Panel header="Product Details" key="2">
                <div className="row" style={{ padding: "1em" }}>
                  <div className="col-md-12 form-group p_star">
                    <label>Product Name</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={productoptions}
                      name="product"
                      onChange={(e) => this.updateProduct(e)}
                      isClearable={true}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <label>
                      Variant (If not selected, please enter value for which new
                      variant will be created)
                    </label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={variantoptions}
                      name="variant"
                      onChange={this.updateVariant}
                      isClearable={true}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showNewVariantSection ? (
                    <React.Fragment>
                      <div className="col-md-6 form-group p_star" id="value">
                        <label>Value</label>
                        <input
                          type="text"
                          className="form-control"
                          name="value"
                          onInput={this.updatePurchaseDetails}
                        />
                      </div>
                      <div
                        className="col-md-4 form-group p_star"
                        id="expiry_date"
                      >
                        <label>Expiry Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="expiry_date"
                          onInput={this.updatePurchaseDetails}
                        />
                      </div>
                      <div className="col-md-2 form-group p_star" id="unit">
                        <label>Unit</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={unitoptions}
                          onChange={this.updateUnit}
                          isClearable={true}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div
                        className="col-md-3 form-group p_star"
                        id="max_retail_price"
                      >
                        <label>Maximum Retail Price</label>
                        <input
                          type="number"
                          className="form-control"
                          name="Max_Retail_Price"
                          onInput={this.updatePurchaseDetails}
                          min={1}
                        />
                      </div>
                      <div
                        className="col-md-3 form-group p_star"
                        id="selling_price"
                      >
                        <label>Selling Price</label>
                        <input
                          type="number"
                          className="form-control"
                          name="Selling_Price"
                          onInput={this.updatePurchaseDetails}
                          min={1}
                        />
                      </div>
                      <div
                        className="col-md-3 form-group p_star"
                        id="selling_tax_rate"
                      >
                        <label>Selling Tax Rate</label>
                        <input
                          type="number"
                          className="form-control"
                          name="Selling_Tax_Rate"
                          onInput={this.updatePurchaseDetails}
                          min={1}
                        />
                      </div>
                      <div className="col-md-3 form-group p_star" id="hsn">
                        <label>HSN/SAC</label>
                        <input
                          type="text"
                          className="form-control"
                          name="HSN_SAC"
                          onInput={this.updatePurchaseDetails}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div />
                  )}
                  <div className="col-md-3 form-group p_star">
                    <label>Rate</label>
                    <input
                      type="number"
                      className="form-control"
                      id="rate"
                      name="rate"
                      onInput={this.updatePurchaseDetails}
                      min={1}
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      name="quantity"
                      onInput={this.updatePurchaseDetails}
                      min={1}
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>Total Amount (Incl taxes)</label>
                    <input
                      type="number"
                      className="form-control"
                      id="total_amount"
                      name="total_amount"
                      value={this.state?.purchase?.["total_amount"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>Tax Rate (%)</label>
                    <input
                      type="number"
                      className="form-control"
                      id="tax_rate"
                      name="Tax_Rate"
                      value={this.state?.purchase?.["Tax_Rate"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>Taxable Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="taxable_amount"
                      name="taxable"
                      value={this.state?.purchase?.["taxable"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>CGST Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="cgst_amount"
                      name="CGST_Amount"
                      value={this.state?.purchase?.["CGST_Amount"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>SGST Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="sgst_amount"
                      name="SGST_Amount"
                      value={this.state?.purchase?.["SGST_Amount"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 form-group p_star">
                    <label>IGST Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="igst_amount"
                      name="IGST_Amount"
                      value={this.state?.purchase?.["IGST_Amount"]}
                      onInput={this.updatePurchaseDetails}
                      min={1}
                      disabled
                    />
                  </div>
                </div>
              </Panel>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="order_box"
                    style={{ background: "transparent", padding: "0px" }}
                  >
                    <button
                      className="btn primary-button mx-3 my-3"
                      style={{
                        color: "white",
                        float: "right",
                      }}
                      onClick={this.createPurchaseEntry}
                    >
                      Create
                    </button>
                    <a
                      href="/purchase/list"
                      style={{
                        color: "white !important",
                        float: "right",
                      }}
                    >
                      <button className="btn primary-button ml-3 my-3">
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
