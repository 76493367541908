import React, { Component } from "react";
import ShowAllPurchasesData from "./show_all_purchase_table_data";
import LoadingSpinner from "./spinner";
import { saveAs } from "file-saver";

export default class ShowMonthlyPurchaseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchases: "",
      loadSpinnerShow: true,
    };

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/view-monthly-purchase", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        start_date: this.props.match.params.start_date,
        end_date: this.props.match.params.end_date,
      }),
    })
      .then((data) => data.json())
      .then((data) => this.setState({ purchases: data }));

    console.log(props);
  }

  componentDidMount() {
    this.setState({ loadSpinnerShow: false });
  }

  fileSave = () => {
    var FileSaver = require("file-saver");
    // FileSaver.saveAs("https://httpbin.org/image", "image.jpg");
    FileSaver.saveAs(this.state.purchases.url, "Report.xlsx");
  };

  render() {
    console.log(this.state);

    let purchasesData;
    if (this.state.purchases) {
      purchasesData = this.state.purchases.results.map((purchase) => (
        <ShowAllPurchasesData
          id={purchase.id}
          entry_date={purchase.entry_date}
          party_name={purchase.party_name}
          bill_amount={purchase.bill_amount}
          invoice_date={purchase.invoice_date}
          invoice_number={purchase.invoice_number}
          gstin={purchase.gstin}
          state_code={purchase.state_code}
          product={purchase.product}
          variant={purchase.variant}
          quantity={purchase.quantity}
          expiry_date={purchase.expiry_date}
        ></ShowAllPurchasesData>
      ));
    }
    return (
      <React.Fragment>
        {this.state.loadSpinnerShow ? (
          <LoadingSpinner />
        ) : (
          <div
            className="container"
            style={{ marginTop: "50px", maxWidth: "100%" }}
          >
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-9">
                    <h3 style={{ marginBottom: "0px" }}>
                      Monthly Retail Purchases (
                      {this.props.match.params.start_date} -{" "}
                      {this.props.match.params.end_date})
                    </h3>
                  </div>
                  {/* <div className="col-md-3">
                    <input type="text" placeholder="Search..." className="form-control" onKeyUp={this.updateSearchString}></input>
                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <table style={{ fontSize: "smaller" }}>
                  <thead>
                    <tr>
                      <th>Party Name</th>
                      <th>Entry Date</th>
                      <th>Bill Amount</th>
                      <th>Invoice Number</th>
                      <th>Invoice Date</th>
                      <th>Waybill No.</th>
                      <th>GSTIN</th>
                      <th>State Code</th>
                      <th>Product</th>
                      <th>Variant</th>
                      <th>Quantity</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>{purchasesData}</tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div
                className="col-md-12"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <div
                  className="order_box"
                  style={{ background: "transparent", padding: "0px" }}
                >
                  <button
                    onClick={this.fileSave}
                    className="btn primary-button"
                    style={{
                      color: "white",
                      float: "right",
                      marginRight: "10px",
                    }}
                  >
                    Download Report
                  </button>
                  <a
                    href="/"
                    className="btn primary-button"
                    style={{
                      color: "white",
                      float: "right",
                      marginRight: "5px",
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
