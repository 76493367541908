import React, { Component } from "react";
import Select from "react-select";

class EditPurchase extends Component {
  constructor(props) {
    super(props);
    let match = props.match;
    this.state = {
      purchase: "",
    };

    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/retail-purchase-list/" +
        match.params.id,
      {
        method: "GET",
      }
    )
      .then((data) => data.json())
      .then((data) => this.setState({ purchase: data }));
  }
  render() {
    const statecodeoptions = [
      { value: "1", label: "Andhra Pradesh(37)" },
      { value: "2", label: "Arunachal Pradesh(12)" },
      { value: "3", label: "Assam(18)" },
      { value: "4", label: "Bihar(10)" },
      { value: "5", label: "Chattisgarh(22)" },
      { value: "6", label: "Delhi(7)" },
      { value: "7", label: "Goa(30)" },
      { value: "8", label: "Gujarat(24)" },
      { value: "9", label: "Haryana(6)" },
      { value: "10", label: "Himachal Pradesh(2)" },
      { value: "11", label: "Jammu and Kashmir(1)" },
      { value: "12", label: "Jharkhand(20)" },
      { value: "13", label: "Karnataka(29)" },
      { value: "14", label: "Kerala(32)" },
      { value: "15", label: "Lakshadweep Islands(31)" },
      { value: "16", label: "Madhya Pradesh(23)" },
      { value: "17", label: "Maharashtra(27)" },
      { value: "18", label: "Manipur(14)" },
      { value: "19", label: "Meghalaya(17)" },
      { value: "20", label: "Mizoram(15)" },
      { value: "21", label: "Nagaland(13)" },
      { value: "22", label: "Odisha(21)" },
      { value: "23", label: "Pondicherry(34)" },
      { value: "24", label: "Punjab(3)" },
      { value: "25", label: "Rajasthan(8)" },
      { value: "26", label: "Sikkim(11)" },
      { value: "27", label: "Tamil Nadu(33)" },
      { value: "28", label: "Telangana(36)" },
      { value: "29", label: "Tripura(16)" },
      { value: "30", label: "Uttar Pradesh(9)" },
      { value: "31", label: "Uttarakhand(5)" },
      { value: "32", label: "West Bengal(19)" },
      { value: "33", label: "Andaman and Nicobar Islands(35)" },
      { value: "34", label: "Chandigarh(4)" },
      { value: "35", label: "Dadra & Nagar Haveli and Daman & Diu(26)" },
      { value: "36", label: "Ladakh(38)" },
      { value: "37", label: "Other Territory(97)" },
    ];

    return (
      <div className="container-fluid ">
        <h3 style={{ marginBottom: "2em" }}>
          Edit Retail Purchase Entry (#19)
        </h3>
        <div
          className="card"
          style={{
            boxShadow: "10px 2px 5px #aaaaaa",
            borderLeft: "5px solid #FFCC01",
          }}
        >
          <div className="card-body">
            <div className="card-header">Party Details :</div>
            <div className="row" style={{ padding: "1em" }}>
              <div className="col-md-12 form-group p_star">
                <label>Party Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="party_name"
                  name="party_name"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.party_name}
                />
              </div>
              <div className="col-md-12 form-group p_star">
                <label>Party Address</label>
                <textarea
                  className="form-control"
                  id="party_address"
                  name="party_address"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.party_address}
                />
              </div>

              <div className="col-md-4 form-group p_star" id="invoice_number">
                <label>Invoice number</label>
                <input
                  type="text"
                  className="form-control"
                  name="invoice_number"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.invoice_number}
                />
              </div>
              <div className="col-md-4 form-group p_star" id="bill_amount">
                <label>Bill Amount</label>
                <input
                  type="number"
                  className="form-control"
                  name="bill_amount"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  value={this.state.bill_amount}
                />
              </div>

              <div className="col-md-4 form-group p_star" id="invoice_date">
                <label>Invoice Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="invoice_date"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.invoice_date}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>Waybill Number (optional)</label>
                <input
                  type="text"
                  className="form-control"
                  id="waybill_number"
                  name="waybill_number"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.waybill_no}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>GSTIN (optional)</label>
                <input
                  type="text"
                  className="form-control"
                  id="gstin"
                  name="gstin"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.gstin}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>State Code</label>
                <Select options={statecodeoptions} />
              </div>

              <div className="col-md-12 form-group p_star">
                <label>Remarks (optional)</label>
                <textarea
                  className="form-control"
                  id="remarks"
                  name="remarks"
                  oninput="updatePurchaseDetails(event)"
                  value={this.state.purchase.remarks}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="card"
          style={{
            marginTop: "3em",
            boxShadow: "10px 2px 5px #aaaaaa",
            borderLeft: "5px solid #FFCC01",
          }}
        >
          <div className="card-body">
            <div className="card-header">Product Details :</div>
            <div className="row" style={{ padding: "1em" }}>
              <div className="col-md-12 form-group p_star" id="product">
                <label>Product</label>
                <input
                  type="text"
                  className="form-control"
                  name="value"
                  oninput="updatePurchaseDetails(event)"
                  defaultValue="Linear Regression"
                  readOnly
                />
              </div>
              <div className="col-md-12 form-group p_star" id="variant">
                <label>Variant</label>
                <input
                  type="text"
                  className="form-control"
                  name="value"
                  oninput="updatePurchaseDetails(event)"
                  defaultValue="Linear Regression (Red) "
                  readOnly
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  value={this.state.purchase.quantity}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>Taxable Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="taxable_amount"
                  name="taxable_amount"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  value={this.state.purchase.taxable}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>Tax Rate</label>
                <input
                  type="number"
                  className="form-control"
                  id="tax_rate"
                  name="tax_rate"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  value={this.state.purchase.Tax_Rate}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>CGST Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="cgst_amount"
                  name="cgst_amount"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  defaultValue={121212121.0}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>SGST Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="sgst_amount"
                  name="sgst_amount"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  defaultValue={1212121.0}
                />
              </div>
              <div className="col-md-4 form-group p_star">
                <label>IGST Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="igst_amount"
                  name="igst_amount"
                  oninput="updatePurchaseDetails(event)"
                  min={1}
                  defaultValue={148484.0}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <a
              className="primary-button"
              style={{
                color: "white",
                float: "right",
                borderRadius: "0px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              onclick="updatePurchaseEntry()"
            >
              Update
            </a>
            <a
              className="primary-button"
              onclick="goBack()"
              style={{
                color: "white",
                float: "right",
                borderRadius: "0px",
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
              }}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPurchase;
