import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div
        className="container-fluid"
        //  style={{ paddingLeft: "260px" }}
      >
        <div className="row">
          <div
            classNam="col-md-12"
            style={{
              backgroundColor: "#F51313",
              width: "100%",
              color: "white",
            }}
          >
            <h6 style={{ margin: "1em", fontSize: "14px", float: "right" }}>
              Copyright © Corona Garia | {new Date().getFullYear()} {" - "}
              {new Date().getFullYear() + 1}
            </h6>
          </div>
        </div>
      </div>
    );
  }
}
