import React, { useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";
import { Box, Card } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";

import Header from "./header";
import Footer from "./footer";
import SidebarNav from "./sidebar";
import LoadingSpinner from "./spinner";

const ShowAllPurchases = () => {
  const [loadSpinnerShow, setLoadSpinnerShow] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [csvData, setCsvData] = useState([[
    "Purchase ID",
    "Entry Date",
    "Party Name",
    "Bill Amount",
    "Invoice Date",
    "Invoice Number",
    "Waybill No",
    "GSTIN",
    "State",
    "State Code",
    "HSN/SAC",
    "Product",
    "Variant",
    "Rate",
    "Quantity",
    "Total",
    "Tax Rate",
    "Taxable",
    "CGST",
    "SGST",
    "IGST",
  ]]);

  useEffect(() => {
    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/retail-purchase-list?limit=${
          pageState.pageSize
        }&offset=${(pageState.page - 1) * 10}&pageNo=${pageState.page}`
      );
      const json = await response.json();
      
      let updated_data_purchage = json.results.map((purchase) => ({
        key: purchase.id,
        id: `#CORO-PUR-${purchase.id}`,
        entry_date: moment(purchase.entry_date).format("DD-MM-YYYY"),
        party_name: purchase.party_name,
        bill_amount: `₹ ${purchase.bill_amount}`,
        invoice_date: moment(purchase.invoice_date).format("DD-MM-YYYY"),
        invoice_number: purchase.invoice_number,
        waybill_no: purchase.waybill_no || "N/A",
        gstin: purchase.gstin || "N/A",
        state: purchase.state,
        state_code: purchase.state_code,
        HSN_SAC: purchase.HSN_SAC,
        product: purchase.product_name,
        variant: purchase.value,
        rate: `₹ ${purchase.rate}`,
        quantity: purchase.quantity,
        total_amount: `₹ ${Math.ceil(purchase.total_amount).toLocaleString(
          "en-IN"
        )}`,
        Tax_Rate: `${purchase.Tax_Rate}%`,
        taxable: `₹ ${Math.ceil(purchase.taxable).toLocaleString("en-IN")}`,
        CGST_Amount:`₹ ${ Math.ceil(purchase.CGST_Amount).toLocaleString("en-IN")}`,
        SGST_Amount: `₹ ${Math.ceil(purchase.SGST_Amount).toLocaleString("en-IN")}`,
        IGST_Amount: `₹ ${Math.ceil(purchase.IGST_Amount).toLocaleString("en-IN")}`,
      }));

      setCsvData([
        [
          
          "Party Name",
          "Bill Amount",
          "Bill Date",
          "Invoice Number",
          "Waybill No",
          "GSTIN",
          "State Code",
          "State",
          "Taxable Value",
          "CGST",
          "SGST",
          "IGST Ammount",
          "Tax Rate",
          "HSN/SAC",
          "Item",
          "Variant",
        "Quantity",
          "Total",
          "Rate",
          
         
          
        ],
        ...updated_data_purchage.map(purchase => [
         
          purchase.party_name,
          purchase.bill_amount?.split("₹")[1],
          purchase.invoice_date,
          purchase.invoice_number,
          purchase.waybill_no,
          purchase.gstin,
          purchase.state_code,
          purchase.state,
          purchase.taxable?.split("₹")[1],
          purchase.CGST_Amount?.split("₹")[1],
          purchase.SGST_Amount?.split("₹")[1],
          purchase.IGST_Amount?.split("₹")[1],
          purchase.Tax_Rate,
          purchase.HSN_SAC,
          purchase.product,
          purchase.variant,
          
          purchase.quantity,
          purchase.total_amount,
          purchase.rate?.split("₹")[1],
         
          
         
        ])
      ]);

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setLoadSpinnerShow(false);
  }, []);

  const updateSearchString = async (e) => {
    const value = e.target.value;
    setSearchString(value);

    if (value === "") {
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/retail-purchase-list?search=${value}`,
          {
            method: "GET",
          }
        );

        const data1 = await response.json();
      } catch (error) {
        console.error("Failed to fetch search results", error);
      }
    }
  };

  const columns = [
    { field: "id", headerName: "Purchase ID", width: 150 },
    { field: "entry_date", headerName: "Entry Date", width: 150 },
    { field: "party_name", headerName: "Party Name", width: 150 },
    { field: "bill_amount", headerName: "Bill Amount", width: 150 },
    { field: "invoice_date", headerName: "Invoice Date", width: 150 },
    { field: "invoice_number", headerName: "Invoice Number", width: 150 },
    { field: "waybill_no", headerName: "Waybill No", width: 150 },
    { field: "gstin", headerName: "GSTIN", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "state_code", headerName: "State Code", width: 150 },
    { field: "HSN_SAC", headerName: "HSN/SAC", width: 150 },
    { field: "product", headerName: "Product", width: 150 },
    { field: "variant", headerName: "Variant", width: 150 },
    { field: "rate", headerName: "Rate", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    { field: "total_amount", headerName: "Total", width: 150 },
    { field: "Tax_Rate", headerName: "Tax Rate", width: 150 },
    { field: "taxable", headerName: "Taxable Amount", width: 150 },
    { field: "CGST_Amount", headerName: "CGST", width: 150 },
    { field: "SGST_Amount", headerName: "SGST", width: 150 },
    { field: "IGST_Amount", headerName: "IGST", width: 150 },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export data" },
          button: { variant: "contained", color: "error" },
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <React.Fragment>
      {loadSpinnerShow ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header />
          <div className="main-body-parent">
            <SidebarNav />
            <div className="container pb-3 pt-3 main-body">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-4 my-2 heading-text">Purchase List</h3>
                </div>
                <div className="col-md-6">
                  <div
                    className="order_box mb-4 my-2"
                    style={{ background: "transparent", padding: "0px" }}
                  >
                    <button
                      onClick={() => {
                        window.location.href = "/purchase/create";
                      }}
                      className="btn primary-button"
                      style={{
                        background: "rgb(245, 19, 19)",
                        color: "white",
                        float: "right",
                      }}
                    >
                      Create Purchase Entry
                    </button>
                    <CSVLink data={csvData} filename="retail-purchases.csv">
                      <button
                        className="btn primary-button mr-2"
                        style={{
                          background: "rgb(245, 19, 19)",
                          color: "white",
                          float: "right",
                        }}
                      >
                        <BsFiletypeCsv className="mr-1" /> Export
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </div>
              <Card>
                <DataGrid
                  autoHeight
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  pagination
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPaginationModelChange={(newPageSize) =>
                    setPageState((old) => ({
                      ...old,
                      pageSize: newPageSize.pageSize,
                      page: newPageSize.page + 1,
                    }))
                  }
                  onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1 }));
                  }}
                  onPageSizeChange={(newPageSize) =>
                    setPageState((old) => ({ ...old, pageSize: newPageSize }))
                  }
                  columns={columns}
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                    },
                    "& .MuiTablePagination-selectLabel": {
                      marginTop: "10px",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      marginTop: "10px",
                    },
                  }}
                />
              </Card>
            </div>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default ShowAllPurchases;
