import axios from "axios";

export const getStateList = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/state-list`
  );
};

export const getCityList = async () => {
  return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/city-list`);
};


export const getFootFall = async () => {
  return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/daily-customer-footfall`);
};


export const getDailySales = async () => {
  return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/daily-sales-list`);
};


export const getLowStock = async () => {
  return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/low-stock-reminder-list`);
};


export const getExpireList = async () => {
  return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/expiry-reminder-list`);
};
