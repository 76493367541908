import { ProSidebarProvider } from "react-pro-sidebar";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Swal from "sweetalert2";
import { FaCashRegister, FaBoxes, FaHome } from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { ImExit } from "react-icons/im";

export default function SidebarNav(props) {
  const logout = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "Do you want to logout ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    });
  };
  return (
    <ProSidebarProvider>
      <Sidebar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="pt-5 pb-2"
        >
          <img src="/static/media/CL.b95e1b6f.svg" style={{ height: "80px" }} />
          <p>Admin</p>
        </div>

        <Menu>
          <a href="/">
            <MenuItem icon={<FaHome />}>Dashboard</MenuItem>
          </a>
          <SubMenu label="Purchase" icon={<BiSolidPurchaseTag />}>
            <a href="/purchase/create">
              <MenuItem>- Create Purchase</MenuItem>
            </a>
            <a href="/purchase/list">
              <MenuItem>- All Purchases</MenuItem>
            </a>
            <a href="/debit-purchase/create">
              <MenuItem>- Create Debit Purchase</MenuItem>
            </a>
            <a href="/debit-purchase/list">
              <MenuItem>- All Debit Purchases</MenuItem>
            </a>
          </SubMenu>

          <SubMenu label="Sale" icon={<FaCashRegister />}>
            <a href="/order/create">
              <MenuItem>- Create Order</MenuItem>
            </a>
            <a href="/order/list">
              <MenuItem>- All Orders</MenuItem>
            </a>
            <a href="/sale/list">
              <MenuItem>- All Sales</MenuItem>
            </a>
            <a href="/order/list">
              <MenuItem>- All Credit Sales</MenuItem>
            </a>
               <a href="/daily-customer-footfall">
              <MenuItem>- Daily Customer Footfall</MenuItem>
            </a>
          </SubMenu>
          <SubMenu label="Financial" icon={<FaBoxes />}>
            <a href="/daily-sales">
              <MenuItem>- Daily Sales</MenuItem>
            </a>
          </SubMenu>
          <SubMenu label="Stock" icon={<FaBoxes />}>
            <a href="/stock/list">
              <MenuItem>- Stock List</MenuItem>
            </a>
            <a href="/stock-expiry-reminder/list">
              <MenuItem>- Stock Expiry List</MenuItem>
            </a>
            <a href="/low-stock">
              <MenuItem>- Low Stock List</MenuItem>
            </a>
          </SubMenu>
          <MenuItem
            icon={<ImExit />}
            style={{ marginBottom: "60px" }}
            onClick={() => {
              logout();
            }}
          >
            {"Logout"}
          </MenuItem>
        </Menu>
      </Sidebar>
    </ProSidebarProvider>
  );
}
