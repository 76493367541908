import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";

// Helpers
import moment from "moment";

// Utils
import { getOnlyFullDate } from "../utils";

// Components
// import { BsFiletypeCsv } from "react-icons/bs";
import Header from "./header";
import Footer from "./footer";
import SidebarNav from "./sidebar";
import LoadingSpinner from "./spinner";
import { FaSearch, FaFileCsv } from "react-icons/fa";
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/material_red.css";

const ShowAllSales = () => {
  const [loadSpinnerShow, setLoadSpinnerShow] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [dateString, setdateString] = useState("");
  const [searchState, setsearchState] = useState(false);
  const [sales, setSales] = useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [pageStatePre, setPageStatePre] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const fp = useRef(null);

  useEffect(() => {
    fetchSalesData();
  }, []);

  const fetchSalesData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "/api/retail-sale-list" +
          (searchString ? `?search=${searchString}` : "")
      );
      const data = await response.json();
      setSales(data);
      setLoadSpinnerShow(false);
    } catch (error) {
      console.error("Error fetching sales data: ", error);
      setLoadSpinnerShow(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/retail-sale-list?limit=${
          pageState.pageSize
        }&offset=${(pageState.page - 1) * 10}&pageNo=${pageState.page}`
      );
      const json = await response.json();
      console.log("json", json);
      setSales(json);
      let updated_data_purchage = json.results.map((sale, index) => ({
        id: sale.id || "N/A",
        party_name: sale.party_name || "N/A",
        entry_date: moment(sale.sale_date).format("DD-MM-YYYY") || "N/A",
        bill_amount:
          "₹ " + Math.ceil(sale.bill_amount).toLocaleString("en-IN") || "N/A",
        invoice_number: sale.invoice_number || "N/A",
        invoice_date: sale.invoice_date || "N/A",
        product: sale.product_name || "N/A",
        variant: sale.variant_value || "N/A",
        quantity: sale.quantity || "N/A",
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
      setPageStatePre((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
    };
    fetchData();
    // loadPurchaseList();
  }, [pageState.page, pageState.pageSize]);

  const updateSearchString = (e) => {
    setSearchString(e.target.value);
  };

  const nextPage = async () => {
    try {
      const getResponse = await fetch(sales.next);
      const data = await getResponse.json();
      setSales(data);
    } catch (error) {
      console.error("Error fetching next page: ", error);
    }
  };

  const prevPage = async () => {
    try {
      const getResponse = await fetch(sales.previous);
      const data = await getResponse.json();
      setSales(data);
    } catch (error) {
      console.error("Error fetching previous page: ", error);
    }
  };

  const csvData = [
    [
      "Purchase ID",
      "Entry Date",
      "Party Name",
      "Bill Amount",
      "Invoice Date",
      "Invoice Number",
      "Waybill No",
      "GSTIN",
      "State Code",
      "State",
      "HSN/SAC",
      "Product",
      "Variant",
      "Rate",
      "Quantity",
      "Total",
      "Tax Rate",
      "Taxable",
      "CGST",
      "SGST",
      "IGST",
    ],
  ];

  const columns = [
    { field: "party_name", headerName: "Party Name", flex: 1 },
    { field: "entry_date", headerName: "Bill Date", flex: 1 },
    { field: "bill_amount", headerName: "Bill Amount", flex: 1 },
    { field: "invoice_number", headerName: "Invoice Number", flex: 1 },
    { field: "invoice_date", headerName: "Invoice Date", flex: 1 },
    { field: "product", headerName: "Product", width: 300 },
    { field: "variant", headerName: "Variant", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
  ];

  // const rows = sales.results.map((sale) => ({
  //   id: sale.id || "N/A",
  //   party_name: sale.party_name || "N/A",
  //   entry_date: moment(sale.sale_date).format("LL") || "N/A",
  //   bill_amount: "₹ " + Math.ceil(sale.bill_amount).toLocaleString("en-IN") || "N/A",
  //   invoice_number: sale.invoice_number || "N/A",
  //   invoice_date: sale.invoice_date || "N/A",
  //   product: sale.product_name || "N/A",
  //   variant: sale.variant_value || "N/A",
  //   quantity: sale.quantity || "N/A",
  // }));
  // console.log("sales", sales);
  sales?.results?.forEach((sale) => {
    let csv_data_row = [];
    csv_data_row.push("#CORO-PUR-" + sale.id);
    csv_data_row.push(moment(sale.entry_date).format("LL"));
    csv_data_row.push(sale.party_name);
    csv_data_row.push(sale.bill_amount);
    csv_data_row.push(moment(sale.invoice_date).format("LL"));
    csv_data_row.push(sale.invoice_number);
    csv_data_row.push(sale.waybill_no);
    csv_data_row.push(sale.gstin);
    csv_data_row.push(sale.state_code);
    csv_data_row.push(sale.state_code);
    csv_data_row.push(sale.HSN_SAC);
    csv_data_row.push(sale.product_name);
    csv_data_row.push(sale.variant_value);
    csv_data_row.push(sale.rate);
    csv_data_row.push(sale.quantity);
    csv_data_row.push(sale.total_amount);
    csv_data_row.push(sale.Tax_Rate);
    csv_data_row.push(sale.taxable);
    csv_data_row.push(sale.CGST_Amount);
    csv_data_row.push(sale.SGST_Amount);
    csv_data_row.push(sale.IGST_Amount);

    csvData.push(csv_data_row);
  });

  const filterDataByDate = (date) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/order-list?date=${date}`, {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => {
        let updated_data_purchage = data.results.map((sale, index) => ({
          id: sale.id || "N/A",
          party_name: sale.party_name || "N/A",
          entry_date: moment(sale.sale_date).format("DD-MM-YYYY") || "N/A",
          bill_amount:
            "₹ " + Math.ceil(sale.bill_amount).toLocaleString("en-IN") || "N/A",
          invoice_number: sale.invoice_number || "N/A",
          invoice_date: sale.invoice_date || "N/A",
          product: sale.product_name || "N/A",
          variant: sale.variant_value || "N/A",
          quantity: sale.quantity || "N/A",
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: updated_data_purchage,
          total: data.count,
        }));
        setsearchState(true);
        setLoadSpinnerShow(false);
      });
  };

  const searchData = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/order-list?search=${searchString}`,
      {
        method: "GET",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let updated_data_purchage = data.results.map((sale, index) => ({
          id: sale.id || "N/A",
          party_name: sale.party_name || "N/A",
          entry_date: moment(sale.sale_date).format("DD-MM-YYYY") || "N/A",
          bill_amount:
            "₹ " + Math.ceil(sale.bill_amount).toLocaleString("en-IN") || "N/A",
          invoice_number: sale.invoice_number || "N/A",
          invoice_date: sale.invoice_date || "N/A",
          product: sale.product_name || "N/A",
          variant: sale.variant_value || "N/A",
          quantity: sale.quantity || "N/A",
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: updated_data_purchage,
          total: data.count,
        }));
        setsearchState(true);
        setLoadSpinnerShow(false);
      });
  };

  return (
    <React.Fragment>
      {loadSpinnerShow ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header />
          <div className="main-body-parent">
            <SidebarNav />
            <div className="container pb-3 pt-3 main-body">
              <div className="row">
                <div className="col-md-7">
                  <h3 className="mb-4 my-2 heading-text">Sale List</h3>
                </div>
                <div className="col-md-5 text-right d-flex align-items-center">
                  {/* <div className="col-md-8"> */}
                  <Flatpickr
                    // className="form-control"
                    value={dateString}
                    placeholder="Select a date"
                    options={{
                      mode: "single",
                      dateFormat: "d-m-Y",
                    }}
                    onChange={(val) => {
                      filterDataByDate(getOnlyFullDate(val));
                    }}
                  />
                  <input
                    className="my-2 mx-1"
                    type="text"
                    placeholder="Search Products...."
                    value={searchString}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        setSearchString(e.target.value);
                      } else {
                        setSearchString("");
                        setsearchState(false);
                      }
                    }}
                  />
                  {/* </div> */}
                  {!searchState ? (
                    // <div className="col-md-4">
                    <button
                      className="btn primary-button my-2 mx-1"
                      onClick={() => searchData()}
                      style={{
                        background: "rgb(245, 19, 19",
                        color: "white",
                        float: "right",
                      }}
                    >
                      <FaSearch />
                    </button>
                  ) : (
                    // </div>
                    <button
                      className="btn primary-button my-2 mx-1"
                      onClick={() => {
                        setSearchString("");
                        setdateString("");
                        setPageState(pageStatePre);
                        setsearchState(false);
                      }}
                      style={{
                        background: "rgb(245, 19, 19",
                        color: "white",
                        float: "right",
                      }}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    // onClick={exportToCSV}
                    className="btn primary-button mx-1"
                    style={{
                      background: "rgb(245, 19, 19",
                      color: "white",
                      float: "right",
                    }}
                  >
                    <FaFileCsv />
                  </button>
                  <button
                    onClick={() => {
                      window.location.href = "/order/create";
                    }}
                    className="btn primary-button mx-1"
                    style={{
                      background: "rgb(245, 19, 19)",
                      color: "white",
                      float: "right",
                      marginRight: "10px",
                    }}
                  >
                    Create
                  </button>
                </div>
              </div>
              <div className="card">
                <DataGrid
                  autoHeight
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  columns={columns}
                  pagination
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPaginationModelChange={(newPageSize) =>
                    setPageState((old) => ({
                      ...old,
                      pageSize: newPageSize.pageSize,
                      page: newPageSize.page + 1,
                    }))
                  }
                  onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1 }));
                  }}
                  onPageSizeChange={(newPageSize) =>
                    setPageState((old) => ({ ...old, pageSize: newPageSize }))
                  }
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                    },
                    "& .MuiTablePagination-selectLabel": {
                      marginTop: "10px",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      marginTop: "10px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default ShowAllSales;
