import axios from "axios";

export const getVariantList = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/variant-list2`
  );
};

export const getVariantById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/variant-list/${id}`
  );
};
