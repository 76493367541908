import axios from "axios";
import CoronaLogo from "./static/images/CL.svg";
import { useState } from "react";
import Swal from "sweetalert2";
import { Redirect as redirect } from "react-router-dom";
import { Card } from "antd";

export default function Login(props) {
  const [authDetails, setAuthDetails] = useState({
    username: undefined,
    password: undefined,
  });

  const updateAuth = (e) => {
    let updatedAuth = Object.assign({}, authDetails);
    updatedAuth[e.target.name] = e.target.value;
    setAuthDetails(updatedAuth);
  };

  const login = () => {
    if (
      authDetails.username !== undefined &&
      authDetails.password !== undefined
    ) {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/api/account/login",
          authDetails
        )
        .then((response) => {
          localStorage.setItem("token", response.data?.token);
          window.location.href = "/";
        })
        .catch((err) => {
          Swal.fire(
            "Login failed!",
            "Unable to login with the provided credentails.",
            "error"
          );
        });
    } else {
      Swal.fire(
        "Login failed!",
        "Please enter username and password.",
        "warning"
      );
    }
  };

  return (
    <div class="login-container">
      <div className="login_parent">
        <Card className="login-card">
          <img
            src={CoronaLogo}
            style={{ height: "150px", marginTop: "30px", marginBottom: "30px" }}
          />

          <input
            type="text"
            name="username"
            placeholder="Username"
            onChange={(e) => updateAuth(e)}
            className="input_form_control p-2 my-2"
          ></input>
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => updateAuth(e)}
            className="input_form_control p-2 my-2"
          ></input>
          <button
            className="my-2 p-2 primary-button"
            onClick={() => {
              login();
            }}
          >
            Login
          </button>
        </Card>
      </div>
    </div>
  );
}
