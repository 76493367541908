import Footer from "../footer";
import Header from "../header";
import SidebarNav from "../sidebar";
// import Select from "react-select";
import moment from "moment";
import { useState, useEffect } from "react";
import { Collapse, Table, Select } from "antd";
import { generateOrderId } from "../../helpers/purchase";
import { getCityList, getStateList } from "../../api/master";
import { getVariantById, getVariantList } from "../../api/product";
import {
  cancelOrder,
  createOrder,
  generateInvoice,
  viewOrder,
} from "../../api/order";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FaDownload } from "react-icons/fa";
const { Panel } = Collapse;

export default function ViewOrder(props) {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  useEffect(() => {
    viewOrder(id).then((response) => {
      setOrder(response.data?.data?.[0]);
    });
  }, []);

  const [orderProducts, setOrderProducts] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [variantList, setVariantList] = useState([]);

  useEffect(() => {
    getStateList().then((response) => {
      setStateList(response.data);
    });

    getCityList().then((response) => {
      setCityList(response.data);
    });
    getVariantList().then((response) => {
      setVariantList(response.data);
    });
  }, []);

  const data = [];
  order?.OrderProducts?.map((item, index) => {
    data.push({
      serial_no: index + 1,
      product: item.product,
      price:
        "₹ " + Math.ceil(item.product_single_price).toLocaleString("en-IN"),
      quantity: item.quantity,
      total: "₹ " + Math.ceil(item.product_total_price).toLocaleString("en-IN"),
    });
  });
  console.log(data);

  //Table Columns
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "serial_no",
      key: "serial_no",
    },

    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const updateOrderDetails = (e) => {
    let updatedOrder = Object.assign({}, order);
    updatedOrder[e.target.name] = e.target.value;
    setOrder(updatedOrder);
  };

  const downloadInvoice = (order_uuid) => {
    generateInvoice(order_uuid).then((response) => {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/${response.data.invoice}`
      );
    });
  };

  return (
    <>
      <Header />
      <div className="main-body-parent">
        <SidebarNav />
        <div className="container pb-3 pt-3 main-body">
          <div className="row">
            <div className="col-md-8">
              <h3 className="mb-4 my-2 heading-text">
                View Order - #{order.order_id}
              </h3>
            </div>
            <div className="col-md-4">
              <div
                className="order_box my-2"
                style={{ background: "transparent", padding: "0px" }}
              >
                <button
                  onClick={() => downloadInvoice(order.order_uuid)}
                  className="btn primary-button"
                  style={{
                    background: "rgb(245, 19, 19)",
                    color: "white",
                    float: "right",
                  }}
                >
                  <FaDownload className="mr-2" />
                  Download Invoice
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Order Details" key="1">
                  <div className="col-md-12">
                    <div className="row contact_form">
                      <div className="col-md-12 form-group p_star">
                        <label htmlFor="order_id">Order ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_id"
                          name="order_id"
                          placeholder="Order ID"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={order.order_id}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_date">Order Date</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_date"
                          name="order_date"
                          placeholder="Order Date"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={moment(order.order_date).format("LL")}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">Order Channel</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue="RETAIL"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">GSTIN</label>
                        <input
                          type="text"
                          className="form-control"
                          id="gstin"
                          name="gstin"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.gstin || "N/A"}
                          disabled
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">
                          Waybill Number (Optional)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="waybill_no"
                          name="waybill_no"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.waybill_no || "N/A"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header="Product Details" key="2">
                  <Table
                    scroll={{ x: 200 }}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                  />
                </Panel>
                <Panel header="Customer Details" key="3">
                  <div className="col-md-12" style={{ marginBottom: "20px" }}>
                    <div className="row">
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_full_name">Full Name</label>
                        <input
                          type="text"
                          id="billing_full_name"
                          name="billing_full_name"
                          onChange={(e) => updateOrderDetails(e)}
                          className="form-control"
                          value={order.customer_name}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_phone_number">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          id="billing_phone_number"
                          name="billing_phone_number"
                          onChange={(e) => updateOrderDetails(e)}
                          className="form-control"
                          value={"+91 " + order.customer_phone_number}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_email">Email</label>
                        <input
                          type="text"
                          id="billing_email"
                          name="billing_email"
                          className="form-control"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.customer_email || "N/A"}
                          disabled
                        />
                      </div>
                      <div className="col-md-12 form-group p_star">
                        <label htmlFor="billing_address">Address</label>
                        <textarea
                          id="billing_address"
                          name="billing_address"
                          className="form-control"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.billing_address}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_state-selectized">State</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Select state"}
                          options={stateList.map((state) => {
                            return {
                              value: state.id,
                              label: state.name,
                            };
                          })}
                          value={parseInt(order.billing_state)}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["billing_state"] = e;
                            setOrder(updatedOrder);
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header="Payment Details" key="4">
                  {" "}
                  <div className="col-md-12" style={{ marginBottom: "20px" }}>
                    <div className="row">
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="order_id">Order Total ( ₹ )</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_total"
                          name="order_total"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={Math.ceil(
                            order.order_total
                          ).toLocaleString("en-IN")}
                          readOnly
                        />
                      </div>
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="order_id">Payment Method</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          name=""
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Select Payment Mode"}
                          options={[
                            {
                              value: "Cash",
                              label: "Cash",
                            },
                            {
                              value: "Card",
                              label: "Card",
                            },
                            {
                              value: "UPI",
                              label: "UPI",
                            },
                          ]}
                          value={order.payment_type}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["payment_method"] = e;
                            setOrder(updatedOrder);
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-md-3 form-group p_star ">
                        <label htmlFor="order_id">Payment Status</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Payment Status"}
                          options={[
                            { value: "Paid", label: "Paid" },
                            { value: "In Process", label: "In Process" },
                          ]}
                          value={order.payment_status}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["payment_status"] = e;
                            setOrder(updatedOrder);
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="payment_transaction_id">
                          Payment Transaction ID (*If Available)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="payment_transaction_id"
                          name="payment_transaction_id"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.payment_transaction_id || "N/A"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="order_box"
                      style={{ background: "transparent", padding: "0px" }}
                    >
                      {/* {order.order_status !== "Cancelled" && ( */}
                      <span
                        className="btn primary-button"
                        style={{
                          color: "white",
                          float: "right",
                          marginTop: "20px",
                          marginBottom: "20px",
                          marginRight: "20px",
                        }}
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, cancel order!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              cancelOrder(order.order_uuid).then((response) => {
                                if (response.data.status) {
                                  Swal.fire(
                                    "Success",
                                    response.data.message,
                                    "success"
                                  );
                                } else {
                                  Swal.fire(
                                    "Failed",
                                    response.data.message,
                                    "error"
                                  );
                                }
                              });
                            }
                          });
                        }}
                      >
                        Cancel Order
                      </span>
                      {/* )} */}
                      <span
                        className="btn primary-button"
                        style={{
                          color: "white",
                          float: "right",
                          marginTop: "20px",
                          marginBottom: "20px",
                          marginRight: "5px",
                        }}
                        onClick={() => {
                          window.location.href = "/order/list";
                        }}
                      >
                        Back
                      </span>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
