export const calculateTax = (total, taxp) => {
  let taxable_amount =
    Math.round(((parseFloat(total) * 100) / (parseFloat(taxp) + 100)) * 100) /
    100;
  let tax_amount = Math.round((total - taxable_amount) * 100) / 100;
  return {
    taxable_amount: taxable_amount,
    tax_amount: tax_amount,
    cgst: tax_amount / 2,
    sgst: tax_amount / 2,
  };
};

export const generateOrderId = () => {
  return `CORO-${new Date().getTime()}`;
};
