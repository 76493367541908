import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import LoadingSpinner from "./spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import Header from "./header";
import SidebarNav from "./sidebar";
import { BiPencil } from "react-icons/bi";
import { FaDownload, FaEye } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";

import Swal from "sweetalert2";
import { generateInvoice } from "../api/order";
import { Parser } from "json2csv";
import { FaSearch, FaFileCsv } from "react-icons/fa";

const ShowAllOrdersTable = () => {
  const [orders, setOrders] = useState(null);
  const [loadSpinnerShow, setLoadSpinnerShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchState, setsearchState] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [pageStatePre, setPageStatePre] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  console.log("searchState", searchState);

  useEffect(() => {
    loadOrderList(1);
  }, []);

  const loadOrderList = (pageNo) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/order-list?limit=10&offset=${
        (pageNo - 1) * 10
      }&pageNo=${pageNo}`,
      {
        method: "GET",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        setOrders(data);
        setLoadSpinnerShow(false);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/order-list?limit=${
          pageState.pageSize
        }&offset=${(pageState.page - 1) * 10}&pageNo=${pageState.page}`
      );
      const json = await response.json();
      console.log("json", json);
      let updated_data_purchage = json.results.map((order, index) => ({
        id: index,
        uuid: order.order_uuid,
        order_id: order.order_id || "N/A",
        customer_name: order.customer_name || "N/A",
        customer_email: order.customer_email || "N/A",
        phone_no: order.customer_phone_number
          ? "+91 " + order.customer_phone_number
          : "N/A",
        order_total:
          "₹ " + Math.ceil(order.order_total).toLocaleString("en-IN") || "N/A",
        payment_type: order.payment_type || "N/A",
        order_status: order.order_status,
        payment_status: order.payment_status,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
      setPageStatePre((old) => ({
        ...old,
        isLoading: false,
        data: updated_data_purchage,
        total: json.count,
      }));
    };
    fetchData();
    // loadPurchaseList();
  }, [pageState.page, pageState.pageSize]);

  const prevPage = async () => {
    const getResponse = await fetch(orders.previous, {
      method: "GET",
    });
    const data = await getResponse.json();
    setOrders(data);
  };

  const nextPage = async () => {
    const getResponse = await fetch(orders.next, {
      method: "GET",
    });
    const data = await getResponse.json();
    setOrders(data);
  };

  const updateSearchString = async (e) => {
    setSearchString(e.target.value);
    if (e.target.value === "") {
      loadOrderList(1);
    } else {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "/api/order-list?search=" +
          e.target.value,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setOrders(data);
    }
  };

  const downloadInvoice = (order_uuid) => {
    generateInvoice(order_uuid).then((response) => {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/${response.data.invoice}`,
        "Download"
      );
    });
  };

  const exportToCSV = () => {
    if (orders && orders.results.length > 0) {
      const fields = [
        "order_id",
        "customer_name",
        "customer_email",
        "phone_no",
        "order_total",
        "order_status",
        "payment_type",
        "payment_status",
      ];
      const opts = { fields };
      const parser = new Parser(opts);
      const csv = parser.parse(orders.results);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "orders.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Confirmed":
        return "#d4edda"; // light green
      case "Cancelled":
        return "#f8d7da"; // light red
      case "Pending":
        return "#fff3cd"; // light yellow
      default:
        return "default";
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "#d4edda"; // light green
      case "Unpaid":
        return "#f8d7da"; // light red
      case "Partial":
        return "#fff3cd"; // light yellow
      default:
        return "default";
    }
  };

  const searchData = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/order-list?search=${searchString}`,
      {
        method: "GET",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let updated_data_purchage = data.results.map((order, index) => ({
          id: index,
          uuid: order.order_uuid,
          order_id: order.order_id || "N/A",
          customer_name: order.customer_name || "N/A",
          customer_email: order.customer_email || "N/A",
          phone_no: order.customer_phone_number
            ? "+91 " + order.customer_phone_number
            : "N/A",
          order_total:
            "₹ " + Math.ceil(order.order_total).toLocaleString("en-IN") ||
            "N/A",
          payment_type: order.payment_type || "N/A",
          order_status: order.order_status,
          payment_status: order.payment_status,
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: updated_data_purchage,
          total: data.count,
        }));
        setsearchState(true);
        setLoadSpinnerShow(false);
      });
  };

  const columns = [
    { field: "order_id", headerName: "Order ID", width: 150 },
    { field: "customer_name", headerName: "Customer Name", width: 200 },
    { field: "phone_no", headerName: "Phone No.", width: 150 },
    { field: "order_total", headerName: "Order Total", width: 150 },
    {
      field: "order_status",
      headerName: "Order Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          style={{
            backgroundColor: getStatusColor(params.value),
            color: "black",
          }}
        />
      ),
    },
    { field: "payment_type", headerName: "Payment Mode", width: 150 },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          style={{
            backgroundColor: getPaymentStatusColor(params.value),
            color: "black",
          }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <Link to={`/order/view/${params.row.uuid}`}>
            <Tooltip title="View Order">
              <button
                style={{
                  border: "none",
                  borderRadius: "50%",
                  padding: "10px",
                  marginRight: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fa8585",
                  color: "white",
                  cursor: "pointer",
                  width: "36px",
                  height: "36px",
                  marginTop: "4px",
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#fa8585")
                }
              >
                <FaEye />
              </button>
            </Tooltip>
          </Link>
          <Tooltip title="Vew Invoice">
            {" "}
            <button
              style={{
                border: "none",
                borderRadius: "50%",
                padding: "10px",
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fa8585",
                color: "white",
                cursor: "pointer",
                width: "36px",
                height: "36px",
                marginTop: "4px",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#0056b3")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fa8585")
              }
              onClick={() => downloadInvoice(params.row.uuid)}
            >
              <FaDownload />
            </button>
          </Tooltip>
          <Tooltip title="Cancel Order">
            <button
              style={{
                border: "none",
                borderRadius: "50%",
                padding: "10px",
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fa8585",
                color: "white",
                cursor: "pointer",
                width: "36px",
                height: "36px",
                marginTop: "4px",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#0056b3")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fa8585")
              }
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, cancel order!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    // Add logic to cancel the order
                  }
                });
              }}
            >
              <RxCross2 />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  let rows = [];
  if (orders) {
    console.log("orders", orders);
    rows = orders.results.map((order, index) => ({
      id: index,
      uuid: order.order_uuid,
      order_id: order.order_id || "N/A",
      customer_name: order.customer_name || "N/A",
      customer_email: order.customer_email || "N/A",
      phone_no: order.customer_phone_number
        ? "+91 " + order.customer_phone_number
        : "N/A",
      order_total:
        "₹ " + Math.ceil(order.order_total).toLocaleString("en-IN") || "N/A",
      payment_type: order.payment_type || "N/A",
      order_status: order.order_status,
      payment_status: order.payment_status,
    }));
  }

  return (
    <React.Fragment>
      {loadSpinnerShow ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header />
          <div className="main-body-parent">
            <SidebarNav />
            <div className="container pb-3 pt-3 main-body">
              <div className="row">
                <div className="col-md-7">
                  <h3 className="mb-4 my-2 heading-text">Order List</h3>
                </div>
                <div className="col-md-5 text-right d-flex align-items-center">
                  {/* <div className="col-md-8"> */}
                  <input
                    className="my-2 mx-1"
                    type="text"
                    placeholder="Search Products...."
                    value={searchString}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        setSearchString(e.target.value);
                      } else {
                        setSearchString("");
                        setsearchState(false);
                      }
                    }}
                  />
                  {/* </div> */}
                  {!searchState ? (
                    // <div className="col-md-4">
                    <button
                      className="btn primary-button my-2 mx-1"
                      onClick={() => searchData()}
                      style={{
                        background: "rgb(245, 19, 19",
                        color: "white",
                        float: "right",
                      }}
                    >
                      <FaSearch />
                    </button>
                  ) : (
                    // </div>
                    <button
                      className="btn primary-button my-2 mx-1"
                      onClick={() => {
                        setSearchString("");
                        setPageState(pageStatePre);
                        setsearchState(false);
                      }}
                      style={{
                        background: "rgb(245, 19, 19",
                        color: "white",
                        float: "right",
                      }}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    onClick={exportToCSV}
                    className="btn primary-button mx-1"
                    style={{
                      background: "rgb(245, 19, 19",
                      color: "white",
                      float: "right",
                    }}
                  >
                    <FaFileCsv />
                  </button>
                  <button
                    onClick={() => {
                      window.location.href = "/order/create";
                    }}
                    className="btn primary-button mx-1"
                    style={{
                      background: "rgb(245, 19, 19)",
                      color: "white",
                      float: "right",
                      marginRight: "10px",
                    }}
                  >
                    Create
                  </button>
                </div>
              </div>
              <div className="card">
                <DataGrid
                  autoHeight
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  columns={columns}
                  pagination
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPaginationModelChange={(newPageSize) =>
                    setPageState((old) => ({
                      ...old,
                      pageSize: newPageSize.pageSize,
                      page: newPageSize.page + 1,
                    }))
                  }
                  onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1 }));
                  }}
                  onPageSizeChange={(newPageSize) =>
                    setPageState((old) => ({ ...old, pageSize: newPageSize }))
                  }
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                    },
                    "& .MuiTablePagination-selectLabel": {
                      marginTop: "10px",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      marginTop: "10px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default ShowAllOrdersTable;
