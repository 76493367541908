import axios from "axios";

export const getDebitPurchaseList = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/retail-debit-purchase-list`
  );
};

export const createDebitPurchase = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/create-retail-debit-purchase`,
    data
  );
};

export const getPurchaseListAll = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/retail-purchase-list-2`
  );
};

export const getPurchaseSingle = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/retail-purchase-list/${id}`
  );
};
