import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Header from "./header";
import Footer from "./footer";
import SidebarNav from "./sidebar";
import { BsFiletypeCsv } from "react-icons/bs";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Chip } from "@mui/material";

import {
  getFootFall,
  getDailySales,
  getLowStock,
  getExpireList,
} from "../../src/api/master";
var Dashboard = () => {
  const columnsSales = [
    { field: "sale_date", headerName: "Date", width: 300 },
    { field: "total_amount", headerName: "Total Amount", width: 150 },
    { field: "total_quantity", headerName: "Total Quantity", width: 150 },
  ];

  const columnsdailyCustomer = [
    { field: "sale_date", headerName: "Date", width: 300 },
    { field: "total_amount", headerName: "Total Amount", width: 150 },
    { field: "total_quantity", headerName: "Total Quantity", width: 150 },
  ];

  const columnsExpire = [
    { field: "product", headerName: "Product", width: 300 },
    { field: "variant", headerName: "Variant", width: 150 },
    { field: "expiry_date", headerName: "Expiry Date", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          style={{
            backgroundColor:
              params.value === "In Stock" ? "#4caf50" : "lightcoral",
            color: "white",
          }}
        />
      ),
    },
    { field: "hsn_sac", headerName: "HSN / SAC", width: 150 },
    { field: "unit", headerName: "Unit", width: 150 },
  ];

  const columnsLowStock = [
    { field: "product", headerName: "Product", width: 300 },
    { field: "variant", headerName: "Variant", width: 150 },
    { field: "expiry_date", headerName: "Expiry Date", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          style={{
            backgroundColor:
              params.value === "In Stock" ? "#4caf50" : "lightcoral",
            color: "white",
          }}
        />
      ),
    },
    { field: "hsn_sac", headerName: "HSN / SAC", width: 150 },
    { field: "unit", headerName: "Unit", width: 150 },
  ];

  const columnsFoot = [
    { field: "order_date", headerName: "Date", width: 300 },
    { field: "customer_count", headerName: "Total Customer", width: 150 },
  ];

  const [rowsfoot, setRowsFoot] = useState([]);
  const [rowsSales, setrowsSales] = useState([]);
  const [rowsLow, setrowsLow] = useState([]);
  const [rowsExpire, setrowsExpire] = useState([]);

  var fetchData = async () => {
    var expireList = await getExpireList();
    var lowstockList = await getLowStock();
    var footfallList = await getFootFall();
    var dailySalesList = await getDailySales();

    if (expireList?.data?.results?.length > 0) {
      console.log("in expire");
      var expireListt = [];
      // expireList?.data?.results?.forEach((product, index) => {
      //   console.log("product", product);
      //   product?.variants?.forEach((variant, index) => {
      //     expireListt.push({
      //       id: index,
      //       product: product.name,
      //       variant: variant.value,
      //       expiry_date: variant.expiry_date,
      //       price: "₹ " + Math.ceil(variant.price).toLocaleString("en-IN"),
      //       quantity: variant.quantity,
      //       status: variant.quantity > 0 ? "In Stock" : "Out of Stock",
      //       hsn_sac: variant.HSN_SAC || "N/A",
      //       unit: variant.unit,
      //     });
      //   });
      // });
      // setrowsExpire(expireListt);
    }

    console.log("lowstockList", lowstockList);
    if (lowstockList?.data?.results?.length > 0) {
      var lowstockListt = [];
      // lowstockList?.data?.results?.forEach((product, index) => {
      //   lowstockListt.push({
      //     id: index,
      //     product: product.product_name,
      //     variant: product.value,
      //     expiry_date: new Date(product.expiry_date).toDateString(),
      //     price: "₹ " + Math.ceil(product.price).toLocaleString("en-IN"),
      //     quantity: product.quantity,
      //     status: product.quantity === 0 ? "Out of Stock" : "Low Stock",
      //     hsn_sac: product.HSN_SAC || "N/A",
      //     unit: product.unit,
      //   });
      // });
      // setrowsLow(lowstockListt);
    }

    if (dailySalesList?.data?.results?.length > 0) {
      var dailyList = [];
      dailySalesList?.data?.results?.forEach((product, index) => {
        dailyList.push({
          id: index,
          sale_date: new Date(product?.sale_date).toDateString(),
          total_amount:
            "₹ " + Math.ceil(product.total_amount).toLocaleString("en-IN"),

          total_quantity: product.total_quantity,
        });
      });
      console.log("dailyList", dailyList);
      setrowsSales(dailyList);
    }

    if (footfallList?.data?.results?.length > 0) {
      var footList = [];
      footfallList?.data?.results?.forEach((product, index) => {
        console.log("product", product);
        footList.push({
          id: index,
          order_date: product.order_date,
          customer_count: product.customer_count,
        });
      });
      setRowsFoot(footList);
    }

    // return;
  };

  useEffect(() => {
    fetchData();
  }, []); // rowsLow.length > 0, rowsSales.length > 0, rowsfoot.length > 0

  return (
    <React.Fragment>
      <Header />
      <div className="main-body-parent">
        <SidebarNav />
        <div className="container pb-3 pt-3 main-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="info-box">
                  <h4>Total Monthly Orders</h4>
                  <div>
                    <h3>
                      {new String(1001).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                    <BsFiletypeCsv className="mr-1" />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="info-box">
                  <h4>Total Monthly Sale</h4>
                  <div>
                    <h3>
                      {new String(1001).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                    <BsFiletypeCsv className="mr-1" />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="info-box">
                  <h4>Total Monthly Purchase</h4>
                  <div>
                    <h3>
                      {new String(1001).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                    <BsFiletypeCsv className="mr-1" />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="info-box">
                  <h4>Total Orders</h4>
                  <div>
                    <h3>
                      {new String(1001).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                    <BsFiletypeCsv className="mr-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h3>Expiry Reminder</h3>
                <DataGrid rows={rowsExpire} columns={columnsExpire} />
              </div>
              <div className="col-md-6">
                <h3>Low Stock Reminder</h3>
                <DataGrid rows={rowsLow} columns={columnsLowStock} />
              </div>
              <div className="col-md-6">
                <h3>Daily Sales</h3>
                <DataGrid
                  rows={rowsSales}
                  columns={columnsSales}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </div>
              <div className="col-md-6">
                <h3>Customer Footfall</h3>
                <DataGrid
                  rows={rowsfoot}
                  columns={columnsFoot}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Dashboard;
