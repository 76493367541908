import React, { Component } from "react";
import { Link } from "react-router-dom";

class ShowAllPurchasesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: "",
      variants: "",
    };
    fetch(process.env.REACT_APP_API_BASE_URL + "/api/product-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ products: data }));

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/variant-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ variants: data }));
  }
  render() {
    console.log(this.state.products);

    let product;
    for (let i = 0; i < this.state.products.length; i++) {
      console.log(this.state.products[i]);
      if (this.props.product === this.state.products[i].id) {
        product = <td>{this.state.products[i].name}</td>;
      }
    }

    let variant;
    for (let i = 0; i < this.state.variants.length; i++) {
      if (this.props.variant == this.state.variants[i].id) {
        variant = <td>{this.state.variants[i].value}</td>;
      }
    }

    return (
      <React.Fragment>
        <tr>
          <th
            style={{
              background: "transparent",
              color: "gray",
              fontWeight: "normal",
            }}
          >
            <Link to={{ pathname: `/purchase/list/${this.props.id}` }}>
              {this.props.party_name}
            </Link>
          </th>
          <td>{this.props.entry_date}</td>
          <td>{this.props.bill_amount}</td>
          <td>{this.props.invoice_number}</td>
          <td>{this.props.invoice_date}</td>
          {this.props.waybill_no ? (
            <td>{this.props.waybill_no}</td>
          ) : (
            <td>N/A</td>
          )}
          {this.props.gstin ? <td>{this.props.gstin}</td> : <td>N/A</td>}
          <td>{this.props.state_code}</td>
          {product}
          {/* {variant} */}
          <td>{this.props.variant}</td>
          <td style={{ width: "1%" }}>{this.props.quantity}</td>
          <td>{this.props.expiry_date}</td>
        </tr>
      </React.Fragment>
    );
  }
}

export default ShowAllPurchasesData;
