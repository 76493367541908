import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Header from "./header";
import Footer from "./footer";

class RetailMain extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        <div className="content">
          <div className="container pb-3 pt-3 main-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h3 style={{ marginBottom: "2em" }}>Purchase</h3>
                </div>
                <a
                  href="/purchase/create"
                  className="col-md-3 col-sm-12 col-xs-12 feature-icon purchase-block"
                >
                  Create a new Purchase
                </a>
                <a
                  href="/purchase/list"
                  className="col-md-3 col-sm-12 col-xs-12 feature-icon purchase-block"
                >
                  Show All Purchases
                </a>
                <a
                  href="/display-monthly-purchase"
                  className="col-md-3 col-sm-12 col-xs-12 feature-icon purchase-block"
                >
                  View Monthly Purchase
                </a>
                <div className="col-md-12">
                  <h3 style={{ marginBottom: "2em" }}>
                    Sale
                    <i
                      className="fas fa-rupee-sign"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                    ></i>{" "}
                  </h3>
                </div>
                <a
                  href="/order/list"
                  className="col-md-3 feature-icon purchase-block"
                >
                  Order Management
                </a>
                <a
                  href="/sale/list"
                  className="col-md-3 feature-icon purchase-block"
                >
                  Show All Sales
                </a>
                <a
                  href="/view-monthly-sale-retail"
                  className="col-md-3 feature-icon purchase-block"
                >
                  View Monthly Sale
                </a>
                <div className="col-md-12">
                  <h3 style={{ marginBottom: "2em" }}>
                    Stock
                    <i
                      className="fa fa-box"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                    ></i>{" "}
                  </h3>
                </div>
                <a
                  href="/stock/list"
                  className="col-md-3 feature-icon purchase-block"
                >
                  Stock Management
                </a>
                <a
                  href="/view-stock-status-by-date"
                  className="col-md-3 feature-icon purchase-block"
                >
                  Stock Report
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default RetailMain;
