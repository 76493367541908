export function getOnlyFullDate(dateString) {
    // JavaScript months are 0-indexed, whilst days are 1-indexed
    // hence DATE.getMonth() > 8 till September
    const DATE = new Date(dateString);

    // console.log(
    //   DATE.getMonth() + 1 + '/' + DATE.getDate() + '/' + DATE.getFullYear(),
    //   'mm/dd/yyyy'
    // );
    var mm = DATE.getMonth() > 8 ? DATE.getMonth() + 1 : '0' + (DATE.getMonth() + 1);
    var dd = DATE.getDate() > 9 ? DATE.getDate() : '0' + DATE.getDate();
    var yyyy = DATE.getFullYear();

    var reformattedDate = dd + '-' + mm + '-' + yyyy;

    // console.log(reformattedDate);
    return reformattedDate;
}