import React, { Component } from "react";
// import Select from "react-select";
import Header from "../header";
import Footer from "../footer";
import SidebarNav from "../sidebar";
import { Collapse, Select } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { calculateTax } from "../../helpers/purchase";
import {
  createDebitPurchase,
  getPurchaseListAll,
  getPurchaseSingle,
} from "../../api/purchase";
const { Panel } = Collapse;

export default class CreateDebitPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseList: [],
      selectedPurchase: {},
      selectedQuantity: null,
    };

    getPurchaseListAll().then((response) => {
      this.setState({ purchaseList: response.data });
    });
  }

  updatePurchaseEntry = (e) => {
    this.setState({ selectedPurchaseId: e });
    getPurchaseSingle(e).then((response) => {
      this.setState({ selectedPurchase: response.data });
    });
  };

  createDebitPurchaseEntry = async () => {
    if (this.state.selectedPurchase.id && this.state.selectedQuantity) {
      createDebitPurchase({
        purchase_id: this.state.selectedPurchase.id,
        return_quantity: this.state.selectedQuantity,
      }).then((response) => {
        Swal.fire("Success!", response.data.message, "success");
      });
    } else {
      Swal.fire("Failed!", "Please enter all fields.", "error");
    }
  };

  render() {
    let quantityOptions = [];
    if (this.state.selectedPurchase) {
      for (let i = 1; i <= this.state.selectedPurchase.quantity; i++) {
        quantityOptions.push({
          label: i,
          value: i,
        });
      }
    } else {
      quantityOptions = [];
    }
    return (
      <>
        <Header />{" "}
        <div className="main-body-parent">
          <SidebarNav />
          <div className="container pb-3 pt-3 main-body">
            <h3 className="mb-4 my-2 heading-text">Create Debit Purchase</h3>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Debit Purchase Details" key="1" active>
                <div className="row" style={{ padding: "1em" }}>
                  <div className="col-md-6 form-group p_star">
                    <label>Select Purchase Entry</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      options={this.state.purchaseList.map((item) => {
                        return {
                          label: "#CORO-PUR-" + item.id,
                          value: item.id,
                        };
                      })}
                      placeholder={"Select Purchase Entry"}
                      onChange={this.updatePurchaseEntry}
                      isClearable={true}
                    />
                  </div>

                  <div className="col-md-6 form-group p_star">
                    <label>Select Quantity</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      options={quantityOptions}
                      onChange={(e) => {
                        this.setState({ selectedQuantity: e });
                      }}
                      isClearable={true}
                      placeholder={"Select Return Quantity"}
                    />
                  </div>
                </div>
              </Panel>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="order_box"
                    style={{ background: "transparent", padding: "0px" }}
                  >
                    <button
                      className="btn primary-button mx-3 my-3"
                      style={{
                        color: "white",
                        float: "right",
                      }}
                      onClick={this.createDebitPurchaseEntry}
                    >
                      Create
                    </button>
                    <a
                      href="/debit-purchase/list"
                      style={{
                        color: "white !important",
                        float: "right",
                      }}
                    >
                      <button className="btn primary-button ml-3 my-3">
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
