import axios from "axios";

export const createOrder = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/retail-order`,
    data
  );
};

export const viewOrder = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/retail-order/${id}`
  );
};

export const generateInvoice = async (order_uuid) => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/generate-retail-invoice?order_uuid=${order_uuid}`
  );
};

export const cancelOrder = async (order_uuid) => {
  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/cancel-order`,
    { order_uuid: order_uuid }
  );
};
