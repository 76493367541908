import Footer from "../footer";
import Header from "../header";
import SidebarNav from "../sidebar";
import moment from "moment";
import { useState, useEffect } from "react";
import { Collapse, Table, Select } from "antd";
import { generateOrderId } from "../../helpers/purchase";
import { getCityList, getStateList } from "../../api/master";
import { getVariantById, getVariantList } from "../../api/product";
import { createOrder } from "../../api/order";
import Swal from "sweetalert2";
const { Panel } = Collapse;

export default function CreateOrder(props) {
  const [order, setOrder] = useState({
    order_id: generateOrderId(),
    order_date: new Date(),
    order_channel: "RETAIL",
    gstin: "",
    waybill_no: "",

    billing_full_name: "",
    billing_phone_number: "",
    billing_email: "",
    billing_address: "",
    billing_state: undefined,
    billing_city: undefined,
    billing_pincode: "",

    order_total: 0.0,
    payment_method: "Cash",
    payment_status: "In Process",
    payment_transaction_id: "",
  });
  const [orderProducts, setOrderProducts] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [discountPercentages, setDiscountPercentages] = useState({});

  useEffect(() => {
    getStateList().then((response) => {
      setStateList(response.data);
    });

    getCityList().then((response) => {
      setCityList(response.data);
    });
    getVariantList().then((response) => {
      setVariantList(response.data);
    });
  }, []);

  const updateDiscountPercentage = (index, value) => {
    const updatedDiscountPercentages = { ...discountPercentages };
    updatedDiscountPercentages[index] = value;
    setDiscountPercentages(updatedDiscountPercentages);
  };

  const getDiscountedPrice = (price, discountPercentage) => {
    const discountAmount = (price * discountPercentage) / 100;
    return price - discountAmount;
  };

  const calculateOrderTotal = () => {
    let orderTotal = 0;
    for (let i = 0; i < orderProducts.length; i++) {
      const product = orderProducts[i];
      const discountedPrice =
        getDiscountedPrice(product.price, discountPercentages[i] || 0);
      orderTotal += discountedPrice * product.quantity;
    }
    setOrder({ ...order, order_total: orderTotal });
  };

  const incProd = (index) => {
    let updatedOrderProducts = [...orderProducts];
    updatedOrderProducts[index].quantity =
      updatedOrderProducts[index].quantity + 1;
    updatedOrderProducts[index].total =
      updatedOrderProducts[index].price * updatedOrderProducts[index].quantity;
    setOrderProducts(updatedOrderProducts);
    calculateOrderTotal();
  };

  const decProd = (index) => {
    let updatedOrderProducts = [...orderProducts];
    if (updatedOrderProducts[index].quantity === 1) {
      updatedOrderProducts.splice(index, 1);
    } else {
      updatedOrderProducts[index].quantity =
        updatedOrderProducts[index].quantity - 1;
      updatedOrderProducts[index].total =
        updatedOrderProducts[index].price *
        updatedOrderProducts[index].quantity;
    }
    setOrderProducts(updatedOrderProducts);
    calculateOrderTotal();
  };

  const data = orderProducts.map((item, index) => ({
    serial_no: index + 1,
    product: item.product,
    variant: item.variant,
    price: "₹ " + Math.ceil(item.price).toLocaleString("en-IN"),
    discount_percentage: (
      <input
        type="number"
        value={discountPercentages[index] || ""}
        onChange={(e) => updateDiscountPercentage(index, e.target.value)}
      />
    ),
    quantity: (
      <>
        <button
          className="btn primary-button"
          style={{
            border: "none",
            borderRadius: "5px",
            padding: "0.25em auto",
            width: "35px",
            height: "35px",
            fontSize: "18px",
            cursor: "pointer",
            padding: "0",
          }}
          onClick={() => {
            decProd(index);
          }}
        >
          -
        </button>
        <span className="mx-3">{item.quantity}</span>
        <button
          className="btn primary-button"
          style={{
            border: "none",
            borderRadius: "5px",
            padding: "0.25em auto",
            width: "35px",
            height: "35px",
            fontSize: "18px",
            cursor: "pointer",
            padding: "0",
          }}
          onClick={() => {
            incProd(index);
          }}
        >
          +
        </button>
      </>
    ),
    total: "₹ " + Math.ceil(item.total).toLocaleString("en-IN"),
    discounted_price:
      "₹ " +
      Math.ceil(
        getDiscountedPrice(item.total, discountPercentages[index] || 0)
      ).toLocaleString("en-IN"),
  }));

  //Table Columns
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "serial_no",
      key: "serial_no",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Variant",
      dataIndex: "variant",
      key: "variant",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Discount %",
      dataIndex: "discount_percentage",
      key: "discount_percentage",
    },
    {
      title: "Discounted Price",
      dataIndex: "discounted_price",
      key: "discounted_price",
    },
  ];

  const orderCreate = () => {
    if (
      order.customer_name === "" ||
      order.customer_email === "" ||
      order.customer_phone_number === "" ||
      order.billing_address === "" ||
      order.billing_state === undefined ||
      // order.billing_city == undefined ||
      orderProducts.length === 0
    ) {
      Swal.fire("Warning", "Please enter all fields!", "warning");
    } else {
      let orderData = {
        order_id: order.order_id,
        order_date: order.order_date,
        channel: order.order_channel,
        gstin: order.gstin,
        waybill_no: order.waybill_no,

        customer_name: order.billing_full_name,
        customer_email: order.billing_email,
        customer_phone_number: order.billing_phone_number,

        billing_address: order.billing_address,
        billing_state: order.billing_state,

        order_total: order.order_total,
        payment_type: order.payment_method,
        payment_transaction_id: order.payment_transaction_id,
        payment_status: order.payment_status,

        order_products: orderProducts,
      };

      createOrder(orderData).then((response) => {
        if (response.data.status) {
          Swal.fire("Success", response.data.message, "success");
          window.location.href = "/order/list";
        } else {
          Swal.fire("Stock not available.", response.data.message, "error");
        }
      });
    }
  };

  const updateOrderDetails = (e) => {
    let updatedOrder = { ...order };
    updatedOrder[e.target.name] = e.target.value;
    setOrder(updatedOrder);
  };

  const addOrderProduct = (e) => {
    let addFlag = false;
    if (e !== undefined) {
      for (let i = 0; i < orderProducts.length; i++) {
        if (e == orderProducts[i].variant_id) {
          Swal.fire(
            "Note",
            `${orderProducts[i].product} - ${orderProducts[i].variant} is already added.`,
            "warning"
          );
          addFlag = true;
        }
      }

      if (!addFlag) {
        getVariantById(e).then((response) => {
          let updatedOrderProducts = [...orderProducts];
          updatedOrderProducts.push({
            product: response.data?.product_name,
            variant: response.data?.value,
            variant_id: response.data?.id,
            price: response.data?.price,
            quantity: 1,
            total: response.data?.price,
          });
          setOrderProducts(updatedOrderProducts);
          calculateOrderTotal();
        });
      }
    }
  };

  return (
    <>
      <Header />
      <div className="main-body-parent">
        <SidebarNav />
        <div className="container pb-3 pt-3 main-body">
          <div className="row">
            <div className="col-md-6">
              <h3 className="mb-4 my-2 heading-text">Create Order</h3>
            </div>
            {/* <div className="col-md-6">
              <div
                className="order_box"
                style={{ background: "transparent", padding: "0px" }}
              >
                <button
                  onClick={() => createOrder()}
                  className="btn primary-button"
                  style={{
                    color: "white",
                    float: "right",
                  }}
                >
                  Download Invoice
                </button>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Order Details" key="1">
                  <div className="col-md-12">
                    <div className="row contact_form">
                      <div className="col-md-12 form-group p_star">
                        <label htmlFor="order_id">Order ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_id"
                          name="order_id"
                          placeholder="Order ID"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={order.order_id}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_date">Order Date</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_date"
                          name="order_date"
                          placeholder="Order Date"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={moment(order.order_date).format("LL")}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">Order Channel</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue="RETAIL"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">GSTIN (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="gstin"
                          name="gstin"
                          onChange={(e) => updateOrderDetails(e)}
                        />
                      </div>
                      <div className="col-md-6 form-group p_star">
                        <label htmlFor="order_id">
                          Waybill Number (Optional)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="waybill_no"
                          name="waybill_no"
                          onChange={(e) => updateOrderDetails(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header="Product Details" key="2">
                  <div className="col-md-12 form-group p_star">
                    <label htmlFor="order_id">Select a Product</label>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      name=""
                      style={{
                        width: "100%",
                      }}
                      placeholder={"Select a product"}
                      options={variantList?.map((variant) => {
                        return {
                          value: variant.id,
                          label: `${variant.product_name} - ${variant.value} (In Stock - ${variant.quantity}) `,
                        };
                      })}
                      onChange={(e) => addOrderProduct(e)}
                      value={"Search product..."}
                    />
                  </div>
                  <Table
                    scroll={{ x: 200 }}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                  />
                </Panel>
                <Panel header="Customer Details" key="3">
                  <div className="col-md-12" style={{ marginBottom: "20px" }}>
                    <div className="row">
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_full_name">Full Name</label>
                        <input
                          type="text"
                          id="billing_full_name"
                          name="billing_full_name"
                          onChange={(e) => updateOrderDetails(e)}
                          className="form-control"
                          value={order.billing_full_name}
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_phone_number">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          id="billing_phone_number"
                          name="billing_phone_number"
                          onChange={(e) => updateOrderDetails(e)}
                          className="form-control"
                          value={order.billing_phone_number}
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_email">Email</label>
                        <input
                          type="text"
                          id="billing_email"
                          name="billing_email"
                          className="form-control"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.billing_email}
                        />
                      </div>
                      <div className="col-md-12 form-group p_star">
                        <label htmlFor="billing_address">Address</label>
                        <textarea
                          id="billing_address"
                          name="billing_address"
                          className="form-control"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.billing_address}
                        />
                      </div>
                      <div className="col-md-4 form-group p_star">
                        <label htmlFor="billing_state-selectized">State</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Select state"}
                          options={stateList.map((state) => {
                            return {
                              value: state.name,
                              label: state.name,
                            };
                          })}
                          value={order.billing_state}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["billing_state"] = e;
                            setOrder(updatedOrder);
                          }}
                        />
                      </div>
                      {/* <div className="col-md-6 form-group p_star ">
                        <label htmlFor="billing_city-selectized">City</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Select city"}
                          options={cityList.map((city) => {
                            return {
                              value: city.id,
                              label: city.name,
                            };
                          })}
                          value={order.billing_city}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["billing_city"] = e;
                            setOrder(updatedOrder);
                          }}
                        />
                      </div>
                      <div className="col-md-12 form-group p_star">
                        <label htmlFor="billing_pincode">Pincode</label>
                        <input
                          className="form-control"
                          type="number"
                          name="billing_pincode"
                          id="billing_pincode"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.billing_pincode}
                        />
                      </div> */}
                    </div>
                  </div>
                </Panel>
                <Panel header="Payment Details" key="4">
                  {" "}
                  <div className="col-md-12" style={{ marginBottom: "20px" }}>
                    <div className="row">
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="order_id">Order Total ( ₹ )</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_total"
                          name="order_total"
                          onChange={(e) => updateOrderDetails(e)}
                          defaultValue={order.order_total}
                          readOnly
                          value={Math.ceil(order.order_total).toLocaleString(
                            "en-IN"
                          )}
                        />
                      </div>
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="order_id">Payment Method</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          name=""
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Select Payment Mode"}
                          options={[
                            {
                              value: "Cash",
                              label: "Cash",
                            },
                            {
                              value: "Card",
                              label: "Card",
                            },
                            {
                              value: "UPI",
                              label: "UPI",
                            },
                          ]}
                          value={order.payment_method}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["payment_method"] = e;
                            setOrder(updatedOrder);
                          }}
                        />
                      </div>
                      <div className="col-md-3 form-group p_star ">
                        <label htmlFor="order_id">Payment Status</label>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder={"Payment Status"}
                          options={[
                            { value: "Paid", label: "Paid" },
                            { value: "In Process", label: "In Process" },
                          ]}
                          value={order.payment_status}
                          onChange={(e) => {
                            let updatedOrder = Object.assign({}, order);
                            updatedOrder["payment_status"] = e;
                            setOrder(updatedOrder);
                          }}
                        />
                      </div>
                      <div className="col-md-3 form-group p_star">
                        <label htmlFor="payment_transaction_id">
                          Payment Transaction ID (*If Available)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="payment_transaction_id"
                          name="payment_transaction_id"
                          onChange={(e) => updateOrderDetails(e)}
                          value={order.payment_transaction_id}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="order_box"
                      style={{ background: "transparent", padding: "0px" }}
                    >
                      <span
                        className="btn primary-button"
                        style={{
                          color: "white",
                          float: "right",
                          marginTop: "20px",
                          marginBottom: "20px",
                          marginRight: "20px",
                        }}
                        onClick={() => orderCreate()}
                      >
                        Create
                      </span>
                      <span
                        className="btn primary-button"
                        style={{
                          color: "white",
                          float: "right",
                          marginTop: "20px",
                          marginBottom: "20px",
                          marginRight: "5px",
                        }}
                        onClick={() => {
                          window.location.href = "/order/list";
                        }}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
