import React, { Component } from "react";
import Select from "react-select";
import SidebarNav from "./sidebar";
import Footer from "./footer";
import Header from "./header";
import { Collapse, Table } from "antd";
const { Panel } = Collapse;

export default class EditOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      products: "",
      variants: "",
    };
    console.log(this.props.match);
    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/order-list/" +
        this.props.match.params.id,
      {
        method: "GET",
      }
    )
      .then((data) => data.json())
      .then((data) => this.setState({ order: data }));

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/variant-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ variants: data }));

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/product-list2", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => this.setState({ products: data }));
  }

  updateOrderDetails = (e) => {};

  addOrderProduct = async (e) => {
    console.log(e.value);
    const postResponse = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/order-product-list",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          order: this.state.order.id,
          variant: e.value,
        }),
      }
    );
    const data = await postResponse.json();
  };

  render() {
    let cityOptionsList = [
      { value: "Adilabad", label: "Adilabad" },
      { value: "Agra", label: "Agra" },
      { value: "Ahmedabad", label: "Ahmedabad" },
      { value: "Ahmednagar", label: "Ahmednagar" },
      { value: "Aizawl", label: "Aizawl" },
      { value: "Ajitgarh (Mohali)", label: "Ajitgarh (Mohali)" },
      { value: "Ajmer", label: "Ajmer" },
      { value: "Akola", label: "Akola" },
      { value: "Alappuzha", label: "Alappuzha" },
      { value: "Aligarh", label: "Aligarh" },
      { value: "Alirajpur", label: "Alirajpur" },
      { value: "Allahabad", label: "Allahabad" },
      { value: "Almora", label: "Almora" },
      { value: "Alwar", label: "Alwar" },
      { value: "Ambala", label: "Ambala" },
      { value: "Ambedkar Nagar", label: "Ambedkar Nagar" },
      { value: "Amravati", label: "Amravati" },
      { value: "Amreli district", label: "Amreli district" },
      { value: "Amritsar", label: "Amritsar" },
      { value: "Anand", label: "Anand" },
      { value: "Anantapur", label: "Anantapur" },
      { value: "Anantnag", label: "Anantnag" },
      { value: "Angul", label: "Angul" },
      { value: "Anjaw", label: "Anjaw" },
      { value: "Anuppur", label: "Anuppur" },
      { value: "Araria", label: "Araria" },
      { value: "Ariyalur", label: "Ariyalur" },
      { value: "Arwal", label: "Arwal" },
      { value: "Ashok Nagar", label: "Ashok Nagar" },
      { value: "Auraiya", label: "Auraiya" },
      { value: "Aurangabad", label: "Aurangabad" },
      { value: "Aurangabad", label: "Aurangabad" },
      { value: "Azamgarh", label: "Azamgarh" },
      { value: "Badgam", label: "Badgam" },
      { value: "Bagalkot", label: "Bagalkot" },
      { value: "Bageshwar", label: "Bageshwar" },
      { value: "Bagpat", label: "Bagpat" },
      { value: "Bahraich", label: "Bahraich" },
      { value: "Baksa", label: "Baksa" },
      { value: "Balaghat", label: "Balaghat" },
      { value: "Balangir", label: "Balangir" },
      { value: "Balasore", label: "Balasore" },
      { value: "Ballia", label: "Ballia" },
      { value: "Balrampur", label: "Balrampur" },
      { value: "Banaskantha", label: "Banaskantha" },
      { value: "Banda", label: "Banda" },
      { value: "Bandipora", label: "Bandipora" },
      { value: "Bangalore Rural", label: "Bangalore Rural" },
      { value: "Bangalore Urban", label: "Bangalore Urban" },
      { value: "Banka", label: "Banka" },
      { value: "Bankura", label: "Bankura" },
      { value: "Banswara", label: "Banswara" },
      { value: "Barabanki", label: "Barabanki" },
      { value: "Baramulla", label: "Baramulla" },
      { value: "Baran", label: "Baran" },
      { value: "Bardhaman", label: "Bardhaman" },
      { value: "Bareilly", label: "Bareilly" },
      { value: "Bargarh (Baragarh)", label: "Bargarh (Baragarh)" },
      { value: "Barmer", label: "Barmer" },
      { value: "Barnala", label: "Barnala" },
      { value: "Barpeta", label: "Barpeta" },
      { value: "Barwani", label: "Barwani" },
      { value: "Bastar", label: "Bastar" },
      { value: "Basti", label: "Basti" },
      { value: "Bathinda", label: "Bathinda" },
      { value: "Beed", label: "Beed" },
      { value: "Begusarai", label: "Begusarai" },
      { value: "Belgaum", label: "Belgaum" },
      { value: "Bellary", label: "Bellary" },
      { value: "Betul", label: "Betul" },
      { value: "Bhadrak", label: "Bhadrak" },
      { value: "Bhagalpur", label: "Bhagalpur" },
      { value: "Bhandara", label: "Bhandara" },
      { value: "Bharatpur", label: "Bharatpur" },
      { value: "Bharuch", label: "Bharuch" },
      { value: "Bhavnagar", label: "Bhavnagar" },
      { value: "Bhilwara", label: "Bhilwara" },
      { value: "Bhind", label: "Bhind" },
      { value: "Bhiwani", label: "Bhiwani" },
      { value: "Bhojpur", label: "Bhojpur" },
      { value: "Bhopal", label: "Bhopal" },
      { value: "Bidar", label: "Bidar" },
      { value: "Bijapur", label: "Bijapur" },
      { value: "Bijapur", label: "Bijapur" },
      { value: "Bijnor", label: "Bijnor" },
      { value: "Bikaner", label: "Bikaner" },
      { value: "Bilaspur", label: "Bilaspur" },
      { value: "Bilaspur", label: "Bilaspur" },
      { value: "Birbhum", label: "Birbhum" },
      { value: "Bishnupur", label: "Bishnupur" },
      { value: "Bokaro", label: "Bokaro" },
      { value: "Bongaigaon", label: "Bongaigaon" },
      { value: "Boudh (Bauda)", label: "Boudh (Bauda)" },
      { value: "Budaun", label: "Budaun" },
      { value: "Bulandshahr", label: "Bulandshahr" },
      { value: "Buldhana", label: "Buldhana" },
      { value: "Bundi", label: "Bundi" },
      { value: "Burhanpur", label: "Burhanpur" },
      { value: "Buxar", label: "Buxar" },
      { value: "Cachar", label: "Cachar" },
      { value: "Central Delhi", label: "Central Delhi" },
      { value: "Chamarajnagar", label: "Chamarajnagar" },
      { value: "Chamba", label: "Chamba" },
      { value: "Chamoli", label: "Chamoli" },
      { value: "Champawat", label: "Champawat" },
      { value: "Champhai", label: "Champhai" },
      { value: "Chandauli", label: "Chandauli" },
      { value: "Chandel", label: "Chandel" },
      { value: "Chandigarh", label: "Chandigarh" },
      { value: "Chandrapur", label: "Chandrapur" },
      { value: "Changlang", label: "Changlang" },
      { value: "Chatra", label: "Chatra" },
      { value: "Chennai", label: "Chennai" },
      { value: "Chhatarpur", label: "Chhatarpur" },
      {
        value: "Chhatrapati Shahuji Maharaj Nagar",
        label: "Chhatrapati Shahuji Maharaj Nagar",
      },
      { value: "Chhindwara", label: "Chhindwara" },
      { value: "Chikkaballapur", label: "Chikkaballapur" },
      { value: "Chikkamagaluru", label: "Chikkamagaluru" },
      { value: "Chirang", label: "Chirang" },
      { value: "Chitradurga", label: "Chitradurga" },
      { value: "Chitrakoot", label: "Chitrakoot" },
      { value: "Chittoor", label: "Chittoor" },
      { value: "Chittorgarh", label: "Chittorgarh" },
      { value: "Churachandpur", label: "Churachandpur" },
      { value: "Churu", label: "Churu" },
      { value: "Coimbatore", label: "Coimbatore" },
      { value: "Cooch Behar", label: "Cooch Behar" },
      { value: "Cuddalore", label: "Cuddalore" },
      { value: "Cuttack", label: "Cuttack" },
      { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
      { value: "Dahod", label: "Dahod" },
      { value: "Dakshin Dinajpur", label: "Dakshin Dinajpur" },
      { value: "Dakshina Kannada", label: "Dakshina Kannada" },
      { value: "Daman", label: "Daman" },
      { value: "Damoh", label: "Damoh" },
      { value: "Dantewada", label: "Dantewada" },
      { value: "Darbhanga", label: "Darbhanga" },
      { value: "Darjeeling", label: "Darjeeling" },
      { value: "Darrang", label: "Darrang" },
      { value: "Datia", label: "Datia" },
      { value: "Dausa", label: "Dausa" },
      { value: "Davanagere", label: "Davanagere" },
      { value: "Debagarh (Deogarh)", label: "Debagarh (Deogarh)" },
      { value: "Dehradun", label: "Dehradun" },
      { value: "Deoghar", label: "Deoghar" },
      { value: "Deoria", label: "Deoria" },
      { value: "Dewas", label: "Dewas" },
      { value: "Dhalai", label: "Dhalai" },
      { value: "Dhamtari", label: "Dhamtari" },
      { value: "Dhanbad", label: "Dhanbad" },
      { value: "Dhar", label: "Dhar" },
      { value: "Dharmapuri", label: "Dharmapuri" },
      { value: "Dharwad", label: "Dharwad" },
      { value: "Dhemaji", label: "Dhemaji" },
      { value: "Dhenkanal", label: "Dhenkanal" },
      { value: "Dholpur", label: "Dholpur" },
      { value: "Dhubri", label: "Dhubri" },
      { value: "Dhule", label: "Dhule" },
      { value: "Dibang Valley", label: "Dibang Valley" },
      { value: "Dibrugarh", label: "Dibrugarh" },
      { value: "Dima Hasao", label: "Dima Hasao" },
      { value: "Dimapur", label: "Dimapur" },
      { value: "Dindigul", label: "Dindigul" },
      { value: "Dindori", label: "Dindori" },
      { value: "Diu", label: "Diu" },
      { value: "Doda", label: "Doda" },
      { value: "Dumka", label: "Dumka" },
      { value: "Dungapur", label: "Dungapur" },
      { value: "Durg", label: "Durg" },
      { value: "East Champaran", label: "East Champaran" },
      { value: "East Delhi", label: "East Delhi" },
      { value: "East Garo Hills", label: "East Garo Hills" },
      { value: "East Khasi Hills", label: "East Khasi Hills" },
      { value: "East Siang", label: "East Siang" },
      { value: "East Sikkim", label: "East Sikkim" },
      { value: "East Singhbhum", label: "East Singhbhum" },
      { value: "Eluru", label: "Eluru" },
      { value: "Ernakulam", label: "Ernakulam" },
      { value: "Erode", label: "Erode" },
      { value: "Etah", label: "Etah" },
      { value: "Etawah", label: "Etawah" },
      { value: "Faizabad", label: "Faizabad" },
      { value: "Faridabad", label: "Faridabad" },
      { value: "Faridkot", label: "Faridkot" },
      { value: "Farrukhabad", label: "Farrukhabad" },
      { value: "Fatehabad", label: "Fatehabad" },
      { value: "Fatehgarh Sahib", label: "Fatehgarh Sahib" },
      { value: "Fatehpur", label: "Fatehpur" },
      { value: "Fazilka", label: "Fazilka" },
      { value: "Firozabad", label: "Firozabad" },
      { value: "Firozpur", label: "Firozpur" },
      { value: "Gadag", label: "Gadag" },
      { value: "Gadchiroli", label: "Gadchiroli" },
      { value: "Gajapati", label: "Gajapati" },
      { value: "Ganderbal", label: "Ganderbal" },
      { value: "Gandhinagar", label: "Gandhinagar" },
      { value: "Ganganagar", label: "Ganganagar" },
      { value: "Ganjam", label: "Ganjam" },
      { value: "Garhwa", label: "Garhwa" },
      { value: "Gautam Buddh Nagar", label: "Gautam Buddh Nagar" },
      { value: "Gaya", label: "Gaya" },
      { value: "Ghaziabad", label: "Ghaziabad" },
      { value: "Ghazipur", label: "Ghazipur" },
      { value: "Giridih", label: "Giridih" },
      { value: "Goalpara", label: "Goalpara" },
      { value: "Godda", label: "Godda" },
      { value: "Golaghat", label: "Golaghat" },
      { value: "Gonda", label: "Gonda" },
      { value: "Gondia", label: "Gondia" },
      { value: "Gopalganj", label: "Gopalganj" },
      { value: "Gorakhpur", label: "Gorakhpur" },
      { value: "Gulbarga", label: "Gulbarga" },
      { value: "Gumla", label: "Gumla" },
      { value: "Guna", label: "Guna" },
      { value: "Guntur", label: "Guntur" },
      { value: "Gurdaspur", label: "Gurdaspur" },
      { value: "Gurgaon", label: "Gurgaon" },
      { value: "Gwalior", label: "Gwalior" },
      { value: "Hailakandi", label: "Hailakandi" },
      { value: "Hamirpur", label: "Hamirpur" },
      { value: "Hamirpur", label: "Hamirpur" },
      { value: "Hanumangarh", label: "Hanumangarh" },
      { value: "Harda", label: "Harda" },
      { value: "Hardoi", label: "Hardoi" },
      { value: "Haridwar", label: "Haridwar" },
      { value: "Hassan", label: "Hassan" },
      { value: "Haveri district", label: "Haveri district" },
      { value: "Hazaribag", label: "Hazaribag" },
      { value: "Hingoli", label: "Hingoli" },
      { value: "Hissar", label: "Hissar" },
      { value: "Hooghly", label: "Hooghly" },
      { value: "Hoshangabad", label: "Hoshangabad" },
      { value: "Hoshiarpur", label: "Hoshiarpur" },
      { value: "Howrah", label: "Howrah" },
      { value: "Hyderabad", label: "Hyderabad" },
      { value: "Hyderabad", label: "Hyderabad" },
      { value: "Idukki", label: "Idukki" },
      { value: "Imphal East", label: "Imphal East" },
      { value: "Imphal West", label: "Imphal West" },
      { value: "Indore", label: "Indore" },
      { value: "Jabalpur", label: "Jabalpur" },
      { value: "Jagatsinghpur", label: "Jagatsinghpur" },
      { value: "Jaintia Hills", label: "Jaintia Hills" },
      { value: "Jaipur", label: "Jaipur" },
      { value: "Jaisalmer", label: "Jaisalmer" },
      { value: "Jajpur", label: "Jajpur" },
      { value: "Jalandhar", label: "Jalandhar" },
      { value: "Jalaun", label: "Jalaun" },
      { value: "Jalgaon", label: "Jalgaon" },
      { value: "Jalna", label: "Jalna" },
      { value: "Jalore", label: "Jalore" },
      { value: "Jalpaiguri", label: "Jalpaiguri" },
      { value: "Jammu", label: "Jammu" },
      { value: "Jamnagar", label: "Jamnagar" },
      { value: "Jamtara", label: "Jamtara" },
      { value: "Jamui", label: "Jamui" },
      { value: "Janjgir-Champa", label: "Janjgir-Champa" },
      { value: "Jashpur", label: "Jashpur" },
      { value: "Jaunpur district", label: "Jaunpur district" },
      { value: "Jehanabad", label: "Jehanabad" },
      { value: "Jhabua", label: "Jhabua" },
      { value: "Jhajjar", label: "Jhajjar" },
      { value: "Jhalawar", label: "Jhalawar" },
      { value: "Jhansi", label: "Jhansi" },
      { value: "Jharsuguda", label: "Jharsuguda" },
      { value: "Jhunjhunu", label: "Jhunjhunu" },
      { value: "Jind", label: "Jind" },
      { value: "Jodhpur", label: "Jodhpur" },
      { value: "Jorhat", label: "Jorhat" },
      { value: "Junagadh", label: "Junagadh" },
      { value: "Jyotiba Phule Nagar", label: "Jyotiba Phule Nagar" },
      {
        value: "Kabirdham (formerly Kawardha)",
        label: "Kabirdham (formerly Kawardha)",
      },
      { value: "Kadapa", label: "Kadapa" },
      { value: "Kaimur", label: "Kaimur" },
      { value: "Kaithal", label: "Kaithal" },
      { value: "Kakinada", label: "Kakinada" },
      { value: "Kalahandi", label: "Kalahandi" },
      { value: "Kamrup", label: "Kamrup" },
      { value: "Kamrup Metropolitan", label: "Kamrup Metropolitan" },
      { value: "Kanchipuram", label: "Kanchipuram" },
      { value: "Kandhamal", label: "Kandhamal" },
      { value: "Kangra", label: "Kangra" },
      { value: "Kanker", label: "Kanker" },
      { value: "Kannauj", label: "Kannauj" },
      { value: "Kannur", label: "Kannur" },
      { value: "Kanpur", label: "Kanpur" },
      { value: "Kanshi Ram Nagar", label: "Kanshi Ram Nagar" },
      { value: "Kanyakumari", label: "Kanyakumari" },
      { value: "Kapurthala", label: "Kapurthala" },
      { value: "Karaikal", label: "Karaikal" },
      { value: "Karauli", label: "Karauli" },
      { value: "Karbi Anglong", label: "Karbi Anglong" },
      { value: "Kargil", label: "Kargil" },
      { value: "Karimganj", label: "Karimganj" },
      { value: "Karimnagar", label: "Karimnagar" },
      { value: "Karnal", label: "Karnal" },
      { value: "Karur", label: "Karur" },
      { value: "Kasaragod", label: "Kasaragod" },
      { value: "Kathua", label: "Kathua" },
      { value: "Katihar", label: "Katihar" },
      { value: "Katni", label: "Katni" },
      { value: "Kaushambi", label: "Kaushambi" },
      { value: "Kendrapara", label: "Kendrapara" },
      { value: "Kendujhar (Keonjhar)", label: "Kendujhar (Keonjhar)" },
      { value: "Khagaria", label: "Khagaria" },
      { value: "Khammam", label: "Khammam" },
      { value: "Khandwa (East Nimar)", label: "Khandwa (East Nimar)" },
      { value: "Khargone (West Nimar)", label: "Khargone (West Nimar)" },
      { value: "Kheda", label: "Kheda" },
      { value: "Khordha", label: "Khordha" },
      { value: "Khowai", label: "Khowai" },
      { value: "Khunti", label: "Khunti" },
      { value: "Kinnaur", label: "Kinnaur" },
      { value: "Kishanganj", label: "Kishanganj" },
      { value: "Kishtwar", label: "Kishtwar" },
      { value: "Kodagu", label: "Kodagu" },
      { value: "Koderma", label: "Koderma" },
      { value: "Kohima", label: "Kohima" },
      { value: "Kokrajhar", label: "Kokrajhar" },
      { value: "Kolar", label: "Kolar" },
      { value: "Kolasib", label: "Kolasib" },
      { value: "Kolhapur", label: "Kolhapur" },
      { value: "Kolkata", label: "Kolkata" },
      { value: "Kollam", label: "Kollam" },
      { value: "Koppal", label: "Koppal" },
      { value: "Koraput", label: "Koraput" },
      { value: "Korba", label: "Korba" },
      { value: "Koriya", label: "Koriya" },
      { value: "Kota", label: "Kota" },
      { value: "Kottayam", label: "Kottayam" },
      { value: "Kozhikode", label: "Kozhikode" },
      { value: "Krishna", label: "Krishna" },
      { value: "Kulgam", label: "Kulgam" },
      { value: "Kullu", label: "Kullu" },
      { value: "Kupwara", label: "Kupwara" },
      { value: "Kurnool", label: "Kurnool" },
      { value: "Kurukshetra", label: "Kurukshetra" },
      { value: "Kurung Kumey", label: "Kurung Kumey" },
      { value: "Kushinagar", label: "Kushinagar" },
      { value: "Kutch", label: "Kutch" },
      { value: "Lahaul and Spiti", label: "Lahaul and Spiti" },
      { value: "Lakhimpur", label: "Lakhimpur" },
      { value: "Lakhimpur Kheri", label: "Lakhimpur Kheri" },
      { value: "Lakhisarai", label: "Lakhisarai" },
      { value: "Lalitpur", label: "Lalitpur" },
      { value: "Latehar", label: "Latehar" },
      { value: "Latur", label: "Latur" },
      { value: "Lawngtlai", label: "Lawngtlai" },
      { value: "Leh", label: "Leh" },
      { value: "Lohardaga", label: "Lohardaga" },
      { value: "Lohit", label: "Lohit" },
      { value: "Lower Dibang Valley", label: "Lower Dibang Valley" },
      { value: "Lower Subansiri", label: "Lower Subansiri" },
      { value: "Lucknow", label: "Lucknow" },
      { value: "Ludhiana", label: "Ludhiana" },
      { value: "Lunglei", label: "Lunglei" },
      { value: "Madhepura", label: "Madhepura" },
      { value: "Madhubani", label: "Madhubani" },
      { value: "Madurai", label: "Madurai" },
      { value: "Mahamaya Nagar", label: "Mahamaya Nagar" },
      { value: "Maharajganj", label: "Maharajganj" },
      { value: "Mahasamund", label: "Mahasamund" },
      { value: "Mahbubnagar", label: "Mahbubnagar" },
      { value: "Mahe", label: "Mahe" },
      { value: "Mahendragarh", label: "Mahendragarh" },
      { value: "Mahoba", label: "Mahoba" },
      { value: "Mainpuri", label: "Mainpuri" },
      { value: "Malappuram", label: "Malappuram" },
      { value: "Maldah", label: "Maldah" },
      { value: "Malkangiri", label: "Malkangiri" },
      { value: "Mamit", label: "Mamit" },
      { value: "Mandi", label: "Mandi" },
      { value: "Mandla", label: "Mandla" },
      { value: "Mandsaur", label: "Mandsaur" },
      { value: "Mandya", label: "Mandya" },
      { value: "Mansa", label: "Mansa" },
      { value: "Marigaon", label: "Marigaon" },
      { value: "Mathura", label: "Mathura" },
      { value: "Mau", label: "Mau" },
      { value: "Mayurbhanj", label: "Mayurbhanj" },
      { value: "Medak", label: "Medak" },
      { value: "Meerut", label: "Meerut" },
      { value: "Mehsana", label: "Mehsana" },
      { value: "Mewat", label: "Mewat" },
      { value: "Mirzapur", label: "Mirzapur" },
      { value: "Moga", label: "Moga" },
      { value: "Mokokchung", label: "Mokokchung" },
      { value: "Mon", label: "Mon" },
      { value: "Moradabad", label: "Moradabad" },
      { value: "Morena", label: "Morena" },
      { value: "Mumbai City", label: "Mumbai City" },
      { value: "Mumbai suburban", label: "Mumbai suburban" },
      { value: "Munger", label: "Munger" },
      { value: "Murshidabad", label: "Murshidabad" },
      { value: "Muzaffarnagar", label: "Muzaffarnagar" },
      { value: "Muzaffarpur", label: "Muzaffarpur" },
      { value: "Mysore", label: "Mysore" },
      { value: "Nabarangpur", label: "Nabarangpur" },
      { value: "Nadia", label: "Nadia" },
      { value: "Nagaon", label: "Nagaon" },
      { value: "Nagapattinam", label: "Nagapattinam" },
      { value: "Nagaur", label: "Nagaur" },
      { value: "Nagpur", label: "Nagpur" },
      { value: "Nainital", label: "Nainital" },
      { value: "Nalanda", label: "Nalanda" },
      { value: "Nalbari", label: "Nalbari" },
      { value: "Nalgonda", label: "Nalgonda" },
      { value: "Namakkal", label: "Namakkal" },
      { value: "Nanded", label: "Nanded" },
      { value: "Nandurbar", label: "Nandurbar" },
      { value: "Narayanpur", label: "Narayanpur" },
      { value: "Narmada", label: "Narmada" },
      { value: "Narsinghpur", label: "Narsinghpur" },
      { value: "Nashik", label: "Nashik" },
      { value: "Navsari", label: "Navsari" },
      { value: "Nawada", label: "Nawada" },
      { value: "Nawanshahr", label: "Nawanshahr" },
      { value: "Nayagarh", label: "Nayagarh" },
      { value: "Neemuch", label: "Neemuch" },
      { value: "Nellore", label: "Nellore" },
      { value: "New Delhi", label: "New Delhi" },
      { value: "Nilgiris", label: "Nilgiris" },
      { value: "Nizamabad", label: "Nizamabad" },
      { value: "North 24 Parganas", label: "North 24 Parganas" },
      { value: "North Delhi", label: "North Delhi" },
      { value: "North East Delhi", label: "North East Delhi" },
      { value: "North Goa", label: "North Goa" },
      { value: "North Sikkim", label: "North Sikkim" },
      { value: "North Tripura", label: "North Tripura" },
      { value: "North West Delhi", label: "North West Delhi" },
      { value: "Nuapada", label: "Nuapada" },
      { value: "Ongole", label: "Ongole" },
      { value: "Osmanabad", label: "Osmanabad" },
      { value: "Pakur", label: "Pakur" },
      { value: "Palakkad", label: "Palakkad" },
      { value: "Palamu", label: "Palamu" },
      { value: "Pali", label: "Pali" },
      { value: "Palwal", label: "Palwal" },
      { value: "Panchkula", label: "Panchkula" },
      { value: "Panchmahal", label: "Panchmahal" },
      {
        value: "Panchsheel Nagar district (Hapur)",
        label: "Panchsheel Nagar district (Hapur)",
      },
      { value: "Panipat", label: "Panipat" },
      { value: "Panna", label: "Panna" },
      { value: "Papum Pare", label: "Papum Pare" },
      { value: "Parbhani", label: "Parbhani" },
      { value: "Paschim Medinipur", label: "Paschim Medinipur" },
      { value: "Patan", label: "Patan" },
      { value: "Pathanamthitta", label: "Pathanamthitta" },
      { value: "Pathankot", label: "Pathankot" },
      { value: "Patiala", label: "Patiala" },
      { value: "Patna", label: "Patna" },
      { value: "Pauri Garhwal", label: "Pauri Garhwal" },
      { value: "Perambalur", label: "Perambalur" },
      { value: "Phek", label: "Phek" },
      { value: "Pilibhit", label: "Pilibhit" },
      { value: "Pithoragarh", label: "Pithoragarh" },
      { value: "Pondicherry", label: "Pondicherry" },
      { value: "Poonch", label: "Poonch" },
      { value: "Porbandar", label: "Porbandar" },
      { value: "Pratapgarh", label: "Pratapgarh" },
      { value: "Pratapgarh", label: "Pratapgarh" },
      { value: "Pudukkottai", label: "Pudukkottai" },
      { value: "Pulwama", label: "Pulwama" },
      { value: "Pune", label: "Pune" },
      { value: "Purba Medinipur", label: "Purba Medinipur" },
      { value: "Puri", label: "Puri" },
      { value: "Purnia", label: "Purnia" },
      { value: "Purulia", label: "Purulia" },
      { value: "Raebareli", label: "Raebareli" },
      { value: "Raichur", label: "Raichur" },
      { value: "Raigad", label: "Raigad" },
      { value: "Raigarh", label: "Raigarh" },
      { value: "Raipur", label: "Raipur" },
      { value: "Raisen", label: "Raisen" },
      { value: "Rajauri", label: "Rajauri" },
      { value: "Rajgarh", label: "Rajgarh" },
      { value: "Rajkot", label: "Rajkot" },
      { value: "Rajnandgaon", label: "Rajnandgaon" },
      { value: "Rajsamand", label: "Rajsamand" },
      {
        value: "Ramabai Nagar (Kanpur Dehat)",
        label: "Ramabai Nagar (Kanpur Dehat)",
      },
      { value: "Ramanagara", label: "Ramanagara" },
      { value: "Ramanathapuram", label: "Ramanathapuram" },
      { value: "Ramban", label: "Ramban" },
      { value: "Ramgarh", label: "Ramgarh" },
      { value: "Rampur", label: "Rampur" },
      { value: "Ranchi", label: "Ranchi" },
      { value: "Ratlam", label: "Ratlam" },
      { value: "Ratnagiri", label: "Ratnagiri" },
      { value: "Rayagada", label: "Rayagada" },
      { value: "Reasi", label: "Reasi" },
      { value: "Rewa", label: "Rewa" },
      { value: "Rewari", label: "Rewari" },
      { value: "Ri Bhoi", label: "Ri Bhoi" },
      { value: "Rohtak", label: "Rohtak" },
      { value: "Rohtas", label: "Rohtas" },
      { value: "Rudraprayag", label: "Rudraprayag" },
      { value: "Rupnagar", label: "Rupnagar" },
      { value: "Sabarkantha", label: "Sabarkantha" },
      { value: "Sagar", label: "Sagar" },
      { value: "Saharanpur", label: "Saharanpur" },
      { value: "Saharsa", label: "Saharsa" },
      { value: "Sahibganj", label: "Sahibganj" },
      { value: "Saiha", label: "Saiha" },
      { value: "Salem", label: "Salem" },
      { value: "Samastipur", label: "Samastipur" },
      { value: "Samba", label: "Samba" },
      { value: "Sambalpur", label: "Sambalpur" },
      { value: "Sangli", label: "Sangli" },
      { value: "Sangrur", label: "Sangrur" },
      { value: "Sant Kabir Nagar", label: "Sant Kabir Nagar" },
      { value: "Sant Ravidas Nagar", label: "Sant Ravidas Nagar" },
      { value: "Saran", label: "Saran" },
      { value: "Satara", label: "Satara" },
      { value: "Satna", label: "Satna" },
      { value: "Sawai Madhopur", label: "Sawai Madhopur" },
      { value: "Sehore", label: "Sehore" },
      { value: "Senapati", label: "Senapati" },
      { value: "Seoni", label: "Seoni" },
      { value: "Seraikela Kharsawan", label: "Seraikela Kharsawan" },
      { value: "Serchhip", label: "Serchhip" },
      { value: "Shahdol", label: "Shahdol" },
      { value: "Shahjahanpur", label: "Shahjahanpur" },
      { value: "Shajapur", label: "Shajapur" },
      { value: "Shamli", label: "Shamli" },
      { value: "Sheikhpura", label: "Sheikhpura" },
      { value: "Sheohar", label: "Sheohar" },
      { value: "Sheopur", label: "Sheopur" },
      { value: "Shimla", label: "Shimla" },
      { value: "Shimoga", label: "Shimoga" },
      { value: "Shivpuri", label: "Shivpuri" },
      { value: "Shopian", label: "Shopian" },
      { value: "Shravasti", label: "Shravasti" },
      { value: "Sibsagar", label: "Sibsagar" },
      { value: "Siddharthnagar", label: "Siddharthnagar" },
      { value: "Sidhi", label: "Sidhi" },
      { value: "Sikar", label: "Sikar" },
      { value: "Simdega", label: "Simdega" },
      { value: "Sindhudurg", label: "Sindhudurg" },
      { value: "Singrauli", label: "Singrauli" },
      { value: "Sirmaur", label: "Sirmaur" },
      { value: "Sirohi", label: "Sirohi" },
      { value: "Sirsa", label: "Sirsa" },
      { value: "Sitamarhi", label: "Sitamarhi" },
      { value: "Sitapur", label: "Sitapur" },
      { value: "Sivaganga", label: "Sivaganga" },
      { value: "Siwan", label: "Siwan" },
      { value: "Solan", label: "Solan" },
      { value: "Solapur", label: "Solapur" },
      { value: "Sonbhadra", label: "Sonbhadra" },
      { value: "Sonipat", label: "Sonipat" },
      { value: "Sonitpur", label: "Sonitpur" },
      { value: "South 24 Parganas", label: "South 24 Parganas" },
      { value: "South Delhi", label: "South Delhi" },
      { value: "South Garo Hills", label: "South Garo Hills" },
      { value: "South Goa", label: "South Goa" },
      { value: "South Sikkim", label: "South Sikkim" },
      { value: "South Tripura", label: "South Tripura" },
      { value: "South West Delhi", label: "South West Delhi" },
      { value: "Sri Muktsar Sahib", label: "Sri Muktsar Sahib" },
      { value: "Srikakulam", label: "Srikakulam" },
      { value: "Srinagar", label: "Srinagar" },
      { value: "Subarnapur (Sonepur)", label: "Subarnapur (Sonepur)" },
      { value: "Sultanpur", label: "Sultanpur" },
      { value: "Sundergarh", label: "Sundergarh" },
      { value: "Supaul", label: "Supaul" },
      { value: "Surat", label: "Surat" },
      { value: "Surendranagar", label: "Surendranagar" },
      { value: "Surguja", label: "Surguja" },
      { value: "Tamenglong", label: "Tamenglong" },
      { value: "Tarn Taran", label: "Tarn Taran" },
      { value: "Tawang", label: "Tawang" },
      { value: "Tehri Garhwal", label: "Tehri Garhwal" },
      { value: "Thane", label: "Thane" },
      { value: "Thanjavur", label: "Thanjavur" },
      { value: "The Dangs", label: "The Dangs" },
      { value: "Theni", label: "Theni" },
      { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
      { value: "Thoothukudi", label: "Thoothukudi" },
      { value: "Thoubal", label: "Thoubal" },
      { value: "Thrissur", label: "Thrissur" },
      { value: "Tikamgarh", label: "Tikamgarh" },
      { value: "Tinsukia", label: "Tinsukia" },
      { value: "Tirap", label: "Tirap" },
      { value: "Tiruchirappalli", label: "Tiruchirappalli" },
      { value: "Tirunelveli", label: "Tirunelveli" },
      { value: "Tirupur", label: "Tirupur" },
      { value: "Tiruvallur", label: "Tiruvallur" },
      { value: "Tiruvannamalai", label: "Tiruvannamalai" },
      { value: "Tiruvarur", label: "Tiruvarur" },
      { value: "Tonk", label: "Tonk" },
      { value: "Tuensang", label: "Tuensang" },
      { value: "Tumkur", label: "Tumkur" },
      { value: "Udaipur", label: "Udaipur" },
      { value: "Udalguri", label: "Udalguri" },
      { value: "Udham Singh Nagar", label: "Udham Singh Nagar" },
      { value: "Udhampur", label: "Udhampur" },
      { value: "Udupi", label: "Udupi" },
      { value: "Ujjain", label: "Ujjain" },
      { value: "Ukhrul", label: "Ukhrul" },
      { value: "Umaria", label: "Umaria" },
      { value: "Una", label: "Una" },
      { value: "Unnao", label: "Unnao" },
      { value: "Upper Siang", label: "Upper Siang" },
      { value: "Upper Subansiri", label: "Upper Subansiri" },
      { value: "Uttar Dinajpur", label: "Uttar Dinajpur" },
      { value: "Uttara Kannada", label: "Uttara Kannada" },
      { value: "Uttarkashi", label: "Uttarkashi" },
      { value: "Vadodara", label: "Vadodara" },
      { value: "Vaishali", label: "Vaishali" },
      { value: "Valsad", label: "Valsad" },
      { value: "Varanasi", label: "Varanasi" },
      { value: "Vellore", label: "Vellore" },
      { value: "Vidisha", label: "Vidisha" },
      { value: "Viluppuram", label: "Viluppuram" },
      { value: "Virudhunagar", label: "Virudhunagar" },
      { value: "Visakhapatnam", label: "Visakhapatnam" },
      { value: "Vizianagaram", label: "Vizianagaram" },
      { value: "Vyara", label: "Vyara" },
      { value: "Warangal", label: "Warangal" },
      { value: "Wardha", label: "Wardha" },
      { value: "Washim", label: "Washim" },
      { value: "Wayanad", label: "Wayanad" },
      { value: "West Champaran", label: "West Champaran" },
      { value: "West Delhi", label: "West Delhi" },
      { value: "West Garo Hills", label: "West Garo Hills" },
      { value: "West Kameng", label: "West Kameng" },
      { value: "West Khasi Hills", label: "West Khasi Hills" },
      { value: "West Siang", label: "West Siang" },
      { value: "West Sikkim", label: "West Sikkim" },
      { value: "West Singhbhum", label: "West Singhbhum" },
      { value: "West Tripura", label: "West Tripura" },
      { value: "Wokha", label: "Wokha" },
      { value: "Yadgir", label: "Yadgir" },
      { value: "Yamuna Nagar", label: "Yamuna Nagar" },
      { value: "Yanam", label: "Yanam" },
      { value: "Yavatmal", label: "Yavatmal" },
      { value: "Zunheboto", label: "Zunheboto" },
    ];

    let stateOptionsList = [
      {
        value: "Andaman and Nicobar Islands",
        label: "Andaman and Nicobar Islands",
      },
      { value: "Andhra Pradesh", label: "Andhra Pradesh" },
      { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
      { value: "Assam", label: "Assam" },
      { value: "Bihar", label: "Bihar" },
      { value: "Chandigarh", label: "Chandigarh" },
      { value: "Chattisgarh", label: "Chattisgarh" },
      {
        value: "Dadra & Nagar Haveli and Daman & Diu",
        label: "Dadra & Nagar Haveli and Daman & Diu",
      },
      { value: "Delhi", label: "Delhi" },
      { value: "Goa", label: "Goa" },
      { value: "Gujarat", label: "Gujarat" },
      { value: "Haryana", label: "Haryana" },
      { value: "Himachal Pradesh", label: "Himachal Pradesh" },
      { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
      { value: "Jharkhand", label: "Jharkhand" },
      { value: "Karnataka", label: "Karnataka" },
      { value: "Kerala", label: "Kerala" },
      { value: "Ladakh", label: "Ladakh" },
      { value: "Lakshadweep Islands", label: "Lakshadweep Islands" },
      { value: "Madhya Pradesh", label: "Madhya Pradesh" },
      { value: "Maharashtra", label: "Maharashtra" },
      { value: "Manipur", label: "Manipur" },
      { value: "Meghalaya", label: "Meghalaya" },
      { value: "Mizoram", label: "Mizoram" },
      { value: "Nagaland", label: "Nagaland" },
      { value: "Odisha", label: "Odisha" },
      { value: "Other Territory", label: "Other Territory" },
      { value: "Pondicherry", label: "Pondicherry" },
      { value: "Punjab", label: "Punjab" },
      { value: "Rajasthan", label: "Rajasthan" },
      { value: "Sikkim", label: "Sikkim" },
      { value: "Tamil Nadu", label: "Tamil Nadu" },
      { value: "Telangana", label: "Telangana" },
      { value: "Tripura", label: "Tripura" },
      { value: "Uttar Pradesh", label: "Uttar Pradesh" },
      { value: "Uttarakhand", label: "Uttarakhand" },
      { value: "West Bengal", label: "West Bengal" },
    ];
    let productOptionsList = [];
    if (this.state.variants && this.state.products) {
      for (let i = 0; i < this.state.variants.length; i++) {
        for (let j = 0; j < this.state.products.length; j++) {
          if (this.state.products[j].id === this.state.variants[i].product) {
            let productOption = {
              value: this.state.variants[i].id,
              label:
                this.state.products[j].name +
                " (" +
                this.state.variants[i].value +
                ") " +
                "Expiry Date - " +
                this.state.variants[i].expiry_date,
            };
            productOptionsList.push(productOption);
          }
        }
      }
    }

    const data = [];
    if (this.state.purchases) {
      this.state.order.OrderProducts.map((purchase, index) => {
        data.push({
          key: index + 1,
          serial_no: purchase.party_name,
          order_id: purchase.id,
          product: purchase.product,
          variant: purchase.value,
          quantity: purchase.quantity,
          price: "₹ " + purchase.price,
          total: purchase.product_total,
        });
      });
    }
    //Table Columns
    const columns = [
      {
        title: "Sl No.",
        dataIndex: "serial_no",
        key: "serial_no",
      },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
      },
      {
        title: "Variant",
        dataIndex: "variant",
        key: "variant",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
    ];

    return (
      <>
        <Header />
        <div className="main-body-parent">
          <SidebarNav />
          <div className="container pb-3 pt-3 main-body">
            <div className="row">
              <div className="col-md-6">
                <h3 className="mb-4 my-2 heading-text">Order ORD1234</h3>
              </div>
              <div className="col-md-6">
                <div
                  className="order_box"
                  style={{ background: "transparent", padding: "0px" }}
                >
                  <button
                    onClick={this.createOrder}
                    className="btn primary-button"
                    style={{
                      color: "white",
                      float: "right",
                    }}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Collapse defaultActiveKey={["1"]}>
                  <Panel header="Details" key="1">
                    {" "}
                    <div className="col-md-8" style={{ marginBottom: "20px" }}>
                      <div className="card">
                        <div className="card-header">
                          <h3>Order Details ({this.state.order.order_id})</h3>
                        </div>
                        <div className="card-body">
                          <div className="row contact_form">
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="order_id">Order ID</label>
                              <input
                                type="text"
                                className="form-control"
                                id="order_id"
                                name="order_id"
                                placeholder="Order ID"
                                onChange={this.updateOrderDetails}
                                defaultValue={this.state.order.order_id}
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="order_date">Order Date</label>
                              <input
                                type="text"
                                className="form-control"
                                id="order_date"
                                name="order_date"
                                placeholder="Order Date"
                                onChange={this.updateOrderDetails}
                                defaultValue={this.state.order.order_date}
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="username">Username</label>
                              <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                placeholder="Username"
                                onChange={this.updateOrderDetails}
                                defaultValue
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="order_status-selectized">
                                Order Status
                              </label>
                              <Select
                                options={[
                                  { value: "Confirmed", label: "Confirmed" },
                                  { value: "Pending", label: "Pending" },
                                  { value: "Returned", label: "Returned" },
                                ]}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="order_id">Order Channel</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="RETAIL"
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="order_id">GSTIN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="gstin"
                                name="gstin"
                                onKeyUp={this.updateOrderDetails}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="order_id">
                                Waybill Number (Optional)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="waybill_no"
                                name="waybill_no"
                                onKeyUp={this.updateOrderDetails}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel header="Products" key="2">
                    <div className="col-md-12 form-group p_star">
                      <div
                        className="alert alert-warning"
                        id="add_product_warning"
                        style={{ textAlign: "center", display: "none" }}
                      >
                        Hello
                      </div>
                      <label htmlFor="order_id">Select a Product</label>
                      <Select
                        options={productOptionsList}
                        onChange={this.addOrderProduct}
                      />
                    </div>
                    <Table dataSource={data} columns={columns} />
                  </Panel>
                  <Panel header="Address" key="3">
                    <div className="col-md-6" style={{ marginBottom: "20px" }}>
                      <div className="card">
                        <div className="card-header">
                          <h3>Billing Address</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="billing_full_name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                id="billing_full_name"
                                name="billing_full_name"
                                onKeyUp={this.updateOrderDetails}
                                className="form-control"
                                value={this.state.order.billing_full_name}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="billing_phone_number">
                                Phone Number
                              </label>
                              <input
                                type="number"
                                id="billing_phone_number"
                                name="billing_phone_number"
                                onKeyUp={this.updateOrderDetails}
                                className="form-control"
                                value={this.state.order.billing_phone_number}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="billing_email">Email</label>
                              <input
                                type="text"
                                id="billing_email"
                                name="billing_email"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.billing_email}
                              />
                            </div>
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="billing_address">Address</label>
                              <textarea
                                id="billing_address"
                                name="billing_address"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.billing_address}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="billing_state-selectized">
                                State
                              </label>
                              <Select options={stateOptionsList} />
                            </div>
                            <div className="col-md-6 form-group p_star ">
                              <label htmlFor="billing_city-selectized">
                                City
                              </label>
                              <Select options={cityOptionsList} />
                            </div>
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="billing_pincode">Pincode</label>
                              <input
                                className="form-control"
                                type="number"
                                name="billing_pincode"
                                id="billing_pincode"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.billing_pincode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginBottom: "20px" }}>
                      <div className="card">
                        <div className="card-header">
                          <h3>Shipping Address</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="shipping_full_name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                id="shipping_full_name"
                                name="shipping_full_name"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.shipping_full_name}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="shipping_phone_number">
                                Phone Number
                              </label>
                              <input
                                type="number"
                                id="shipping_phone_number"
                                name="shipping_phone_number"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.shipping_phone_number}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="shipping_email">Email</label>
                              <input
                                type="text"
                                id="shipping_email"
                                name="shipping_email"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.shipping_email}
                              />
                            </div>
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="shipping_address">Address</label>
                              <textarea
                                id="shipping_address"
                                name="shipping_address"
                                className="form-control"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.shipping_address}
                              />
                            </div>
                            <div className="col-md-6 form-group p_star">
                              <label htmlFor="billing_state-selectized">
                                State
                              </label>
                              <Select options={stateOptionsList} />
                            </div>
                            <div className="col-md-6 form-group p_star ">
                              <label htmlFor="shipping_city-selectized">
                                City
                              </label>
                              <Select options={cityOptionsList} />
                            </div>
                            <div className="col-md-12 form-group p_star">
                              <label htmlFor="shipping_pincode">Pincode</label>
                              <input
                                className="form-control"
                                type="number"
                                name="shipping_pincode"
                                id="shipping_pincode"
                                onKeyUp={this.updateOrderDetails}
                                value={this.state.order.shipping_pincode}
                              />
                            </div>
                            <div
                              className="col-md-12 form-group p_star"
                              style={{ marginBottom: "0px" }}
                            >
                              <div
                                className="order_box"
                                style={{
                                  background: "transparent",
                                  padding: "0px",
                                }}
                              >
                                <a
                                  className="btn primary-button"
                                  style={{ color: "white", float: "right" }}
                                >
                                  Same as Billing Address
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel header="Payment" key="4">
                    {" "}
                    <div className="col-md-4" style={{ marginBottom: "20px" }}>
                      <div className="card">
                        <div className="card-header">
                          <h3>Payment Details</h3>
                        </div>
                        <div className="card-body">
                          <div className="col-md-12 form-group p_star">
                            <label htmlFor="order_id">Order Total</label>
                            <input
                              type="text"
                              className="form-control"
                              id="order_total"
                              name="order_total"
                              onKeyUp={this.updateOrderDetails}
                              defaultValue={this.state.order.total}
                              readOnly
                            />
                          </div>
                          <div className="col-md-12 form-group p_star">
                            <label htmlFor="order_id">Payment Method</label>
                            <Select
                              options={[
                                {
                                  value: "Pay on Delivery",
                                  label: "Pay on Delivery",
                                },
                                { value: "Card", label: "Card" },
                                { value: "Paytm", label: "Paytm" },
                              ]}
                            />
                          </div>
                          <div className="col-md-12 form-group p_star ">
                            <label htmlFor="order_id">Payment Status</label>
                            <Select
                              options={[
                                { value: "Confirmed", label: "Confirmed" },
                                { value: "Prnding", label: "Pending" },
                              ]}
                            />
                          </div>
                          <div className="col-md-12 form-group p_star">
                            <label htmlFor="payment_transaction_id">
                              Payment Transaction ID (*If Available)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="payment_transaction_id"
                              name="payment_transaction_id"
                              onKeyUp={this.updateOrderDetails}
                            />
                          </div>
                          <div className="col-md-12 form-group p_star"></div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="order_box"
                        style={{ background: "transparent", padding: "0px" }}
                      >
                        <a
                          className="btn primary-button"
                          style={{
                            color: "white",
                            float: "right",
                            marginTop: "20px",
                            marginBottom: "20px",
                            marginRight: "20px",
                          }}
                        >
                          Update
                        </a>
                        <a
                          onClick={this.goBack}
                          className="btn primary-button"
                          style={{
                            color: "white",
                            float: "right",
                            marginTop: "20px",
                            marginBottom: "20px",
                            marginRight: "5px",
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

// class OrderProductsTableData extends Component {
//   render() {
//     return (
//       <tr>
//         <th style={{ background: "transparent", color: "gray" }}>
//           {this.props.sl_no}
//         </th>
//         <td>{this.props.name}</td>
//         <td>{this.props.value}</td>
//         <td>{this.props.quantity}</td>
//         <td>{this.props.price}</td>
//         <td>{this.props.total}</td>
//         <td>
//           <button
//             className="btn btn-primary btn-sm"
//             style={{
//               backgroundColor: "#F51313",
//               border: "none",
//               marginRight: "5px",
//             }}
//           >
//             <i className="fa fa-plus" />
//           </button>
//           <button
//             className="btn btn-primary btn-sm"
//             style={{ backgroundColor: "#F51313", border: "none" }}
//           >
//             <i className="fa fa-minus" />
//           </button>
//         </td>
//         <td>
//           <button
//             className="btn btn-warning btn-sm delete-btn"
//             style={{
//               backgroundColor: "#F51313",
//               color: "white",
//               border: "none",
//             }}
//           >
//             Delete
//           </button>
//         </td>
//       </tr>
//     );
//   }
// }
