import React, { Component } from "react";
import RetailMain from "./retail_main";
import ShowAllPurchases from "./show_all_purchase_table";
import EditPurchase from "./edit_purchase";
import CreatePurchase from "./create_purchase";
import ShowAllSales from "./show_all_sale_table";
import ShowMonthlyPurchase from "./show_monthly_purchase";
import ShowMonthlyPurchaseTable from "./show_monthly_purchase_table";
import ShowAllOrdersTable from "./show_all_orders_table";
import EditOrder from "./edit_order";
import ShowStockTable from "./show_stock_table";

//New Components
import CreateOrder from "./Order/Create";

//End Import New Components
import "./static/css/linearicons.css";
import "./static/css/font-awesome.min.css";
import "./static/css/themify-icons.css";
import "./static/css/bootstrap.css";
// import "./static/css/owl.carousel.css";
import "./static/css/nice-select.css";
import "./static/css/nouislider.min.css";
import "./static/css/ion.rangeSlider.css";
// import "./static/css/ion.rangeSlider.skinFlat.css";
import "./static/css/magnific-popup.css";
// import "./static/css/main.css";
import "./static/style.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Protected from "./Protected";
import Login from "./login";
import Dashboard from "./dashboard";
import PageNotFound from "./404";
import ViewOrder from "./Order/View";
import DebitPurchase from "./Purchase/debit_purchase_list";
import CreateDebitPurchase from "./Purchase/create_debit_purchase";
import StockExpire from "./Expire/stock_expire";
import LowStockList from "./Expire/low_stock";
import DailySalesList from "./Sales/dailySales";
import DailyCustomerFootfall from "./Sales/dailyCustomerFootfall";

export default class Body extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Protected exact path="/" component={Dashboard} />
            <Protected
              path="/purchase/create"
              exact
              component={CreatePurchase}
            />

            <Protected
              path="/purchase/list"
              exact
              component={ShowAllPurchases}
            />

            <Protected
              path="/debit-purchase/create"
              exact
              component={CreateDebitPurchase}
            />

            <Protected
              path="/debit-purchase/list"
              exact
              component={DebitPurchase}
            />

            <Protected
              path="/purchase/list/:id"
              exact
              component={EditPurchase}
            />
            <Protected path="/sale/list" exact component={ShowAllSales} />
            <Protected
              path="/display-monthly-purchase"
              exact
              component={ShowMonthlyPurchase}
            />
            <Protected
              path="/display-monthly-purchase-table/:start_date/:end_date"
              exact
              component={ShowMonthlyPurchaseTable}
            />

            <Protected path="/order/create" exact component={CreateOrder} />
            <Protected
              path="/order/list"
              exact
              component={ShowAllOrdersTable}
            />
            <Protected path="/order/view/:id" exact component={ViewOrder} />
            <Protected path="/edit-order/:id" exact component={EditOrder} />
            <Protected path="/stock/list" exact component={ShowStockTable} />

            {/* Krishan route */}
            <Protected
              path="/stock-expiry-reminder/list"
              exact
              component={StockExpire}
            />
             <Protected
              path="/low-stock"
              exact
              component={LowStockList}
            />

             <Protected
              path="/daily-sales"
              exact
              component={DailySalesList}
            />

            
             <Protected
              path="/daily-customer-footfall"
              exact
              component={DailyCustomerFootfall}
            />

            {/*  */}
            <Route path="*" component={PageNotFound} />

            {/* <Route path='/purchase/list/:id' exact component={EditPurchase} /> */}
          </Switch>
        </Router>
        {/* <Footer /> */}
        {/* <link rel="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"></link> */}
        {/* <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"></script> */}
      </React.Fragment>
    );
  }
}
