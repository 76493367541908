import React, { Component } from "react";
import { Link } from "react-router-dom";

class ShowMonthlyPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: "",
      end_date: "",
      purchases: "",
    };
  }
  updateDate = (e) => {
    if (e.target.name === "start_date") {
      let updatedStartDate = e.target.value;
      this.setState({ start_date: updatedStartDate });
    } else if (e.target.name === "end_date") {
      let updatedEndDate = e.target.value;
      this.setState({ end_date: updatedEndDate });
    }

    console.log(this.state.start_date, this.state.end_date);
  };

  viewResults = async () => {
    if (this.state.start_date != "" && this.state.end_date != "") {
      let postResp = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/api/view-monthly-purchase",
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            start_date: this.state.start_date,
            end_date: this.state.end_date,
          }),
        }
      );
      const data = await postResp.json();
      this.setState({ purchases: data });
    } else {
      alert("Please enter all fields!");
    }
  };

  render() {
    console.log(this.state.purchases);
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <h3 style={{ marginTop: "1em", marginBottom: "1em" }}>
                View Purchases by Date:
              </h3>
              <div
                className="card"
                style={{
                  marginBottom: "3em",
                  marginTop: "3em",
                  boxShadow: "10px 2px 5px #aaaaaa",
                  borderLeft: "5px solid #F51313",
                }}
              >
                <div className="row" style={{ padding: "1em" }}>
                  <div className="col-md-12 form-group p_star">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="start_date"
                      onChange={this.updateDate}
                      name="start_date"
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="end_date"
                      onChange={this.updateDate}
                      name="end_date"
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <div
                      className="order_box"
                      style={{ background: "transparent", padding: "0px" }}
                    >
                      {this.state.start_date && this.state.end_date ? (
                        <Link
                          to={{
                            pathname:
                              "/display-monthly-purchase-table/" +
                              this.state.start_date +
                              "/" +
                              this.state.end_date,
                          }}
                          className="btn primary-button"
                          style={{
                            border: "0px",
                            color: "white",
                            float: "right",
                          }}
                        >
                          View Results
                        </Link>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="order_box"
                style={{ background: "transparent", padding: "0px" }}
              >
                <a
                  href="/"
                  className="btn primary-button"
                  style={{
                    color: "white",
                    float: "right",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ShowMonthlyPurchase;
