import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.min.css";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner = (props) => (
  <div className={classNames("-loading", { "active -active": props.loading })}>
    <div
      id="spinner"
      style={{
        top: "50%",
        left: "50%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack sx={{ color: "rgb(245, 19, 19)" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingSpinner;
