import React, { Component } from "react";
import CoronaLogo from "./static/images/CL.svg";
import { ImExit } from "react-icons/im";
import Swal from "sweetalert2";

export default class Header extends Component {
  render() {
    return (
      <div className="container-fluid py-1 navbar-top-fixed">
        <a href="/">
          <img src={CoronaLogo} />
        </a>
        <span
          className="pull-right"
          style={{ cursor: "pointer", marginTop: "13px" }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure ?",
              text: "Do you want to logout ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Logout!",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("token");
                window.location.href = "/login";
              }
            });
          }}
        >
          <ImExit /> Logout
        </span>
      </div>
    );
  }
}
